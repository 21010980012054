import { productActionTypes } from "../../actionTypes/product/productsActionTypes";

const initialState = {
  stateInfo: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: null,
  },
  products: [],
  product: null,
  productAttributes: [],
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case productActionTypes.START_GET_PRODUCTS:
    case productActionTypes.START_GET_PRODUCT:
    case productActionTypes.START_ADD_PRODUCT:
    case productActionTypes.START_UPDATE_PRODUCT:
    case productActionTypes.START_DELETE_PRODUCT:
    case productActionTypes.START_GET_PRODUCT_ATTRIBUTE:
      return setStateLoading(state);

    case productActionTypes.GET_PRODUCTS_FAILURE:
    case productActionTypes.GET_PRODUCT_FAILURE:
    case productActionTypes.ADD_PRODUCT_FAILURE:
    case productActionTypes.UPDATE_PRODUCT_FAILURE:
    case productActionTypes.DELETE_PRODUCT_FAILURE:
    case productActionTypes.GET_PRODUCT_ATTRIBUTE_FAILURE:
      return setStateFailure(state, action);

    case productActionTypes.GET_PRODUCTS_SUCCESS:
      return setProducts(state, action);

    case productActionTypes.GET_PRODUCT_SUCCESS:
      return setProduct(state, action);

    case productActionTypes.GET_PRODUCT_ATTRIBUTE_SUCCESS:
      return setProductAttributes(state, action);

    case productActionTypes.ADD_PRODUCT_SUCCESS:
    case productActionTypes.UPDATE_PRODUCT_SUCCESS:
    case productActionTypes.DELETE_PRODUCT_SUCCESS:
      return setStateSuccess(state);

    default:
      return state;
  }
};

const setStateLoading = (state) => {
  return {
    ...state,
    stateInfo: {
      isLoading: true,
      isSuccess: false,
      isError: false,
      error: null,
    },
  };
};

const setStateFailure = (state, action) => {
  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: false,
      isError: true,
      error: action.data,
    },
  };
};

const setProducts = (state, action) => {
  return {
    ...state,
    products: action.data,
    stateInfo: {
      isLoading: false,
      isSuccess: true,
      isError: false,
      error: null,
    },
  };
};

const setProduct = (state, action) => {
  return {
    ...state,
    product: action.data,
    stateInfo: {
      isLoading: false,
      isSuccess: true,
      isError: false,
      error: null,
    },
  };
};

const setStateSuccess = (state) => {
  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: true,
      isError: false,
      error: null,
    },
  };
};

const setProductAttributes = (state, action) => {
  return {
    ...state,
    productAttributes: action.data,
    stateInfo: {
      isLoading: false,
      isSuccess: true,
      isError: false,
      error: null,
    },
  };
};

export default productReducer;
