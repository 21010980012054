import { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import categoryActions from "../store/actions/product/category.actions";
import productActions from "../store/actions/product/product.actions";


// Import Swiper styles
// import "swiper/css";
import HomePageCategoriesListComponent from "../components/homePage/HomePageCategoriesListComponent";
import HomePageBannerComponent from "../components/homePage/Banner/HomePageBannerComponent";

const HomePage = ({ categoryActions, productActions }) => {
  return (
    <div className="home-page-container">
      <HomePageBannerComponent />
      <HomePageCategoriesListComponent />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  userReducer: state.userReducer,
  websiteReducer: state.websiteReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    categoryActions: bindActionCreators(categoryActions, dispatch),
    productActions: bindActionCreators(productActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
