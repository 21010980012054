import { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import ProductCardListComponent from "../../components/products/ProductCardList";
import categoryActions from "../../store/actions/product/category.actions";
import productActions from "../../store/actions/product/product.actions";
import { useParams } from "react-router-dom";
import ProductsFilterComponent from "../../components/products/ProductsFilterComponent";

const ProductListPage = ({ categoryActions, productActions, productReducer, categoryReducer }) => {
  const { categoryRoute, parentCategoryId, childCategoryRoute } = useParams();
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [productsAttributes, setProductAttributes] = useState([]);
  const [selectedAttributesValues, setSelectedAttributesValues] = useState({});

  useEffect(() => {
    categoryActions.getCategories({ pageNumber: 1, pageSize: -1 });
    productActions.getProductAttributes();
  }, []);

  useEffect(() => {
    const selectedCategory = findCategoryByParams();
    if (selectedCategory) setCategory(selectedCategory);
  }, [categoryRoute, parentCategoryId, childCategoryRoute, categoryReducer.categories]);

  useEffect(() => {
    if (category) {
      fetchInitialProducts();
    }
  }, [category]);

  useEffect(() => {
    if (productReducer.productAttributes) {
      setProductAttributes(productReducer.productAttributes);
    }
  }, [productReducer.productAttributes]);

  useEffect(() => {
    // Reset page, products, and hasMore when attributes are selected or changed
    resetAndFetchProducts();
  }, [selectedAttributesValues]);

  useEffect(() => {
    const localProductsCount = products?.length;
    const stateProductsCount = productReducer?.products?.items?.length;

    if (page !== productReducer?.products?.currentPage && localProductsCount !== stateProductsCount && productReducer?.products.items) {
      setProducts((prevProducts) => [...prevProducts, ...productReducer.products.items]);
    }
  }, [productReducer.products, categoryReducer.categories]);

  const findCategoryByParams = () => {
    let category;
    if (categoryRoute) {
      category = categoryReducer?.categories?.items?.find((c) => c.categoryRoute === categoryRoute);
    } else if (parentCategoryId && childCategoryRoute) {
      const parentCategory = categoryReducer?.categories?.items?.find((c) => c.id === parentCategoryId);
      category = parentCategory?.subCategories?.find((c) => c.categoryRoute === childCategoryRoute);
    }
    return category;
  };

  const fetchInitialProducts = () => {
    if (category) {
      setPage(1); // Reset page to 1
      setProducts([]); // Clear current products
      setHasMore(true); // Reset hasMore to true
      productActions.getProducts({
        pageNumber: 1,
        pageSize: 10,
        categoryId: category.id,
      });
      setPage(2); // Increment page number
    }
  };

  const resetAndFetchProducts = () => {
    setPage(1); // Reset page to 1
    setProducts([]); // Clear current products
    setHasMore(true); // Reset hasMore to true

    const attributesString = Object.values(selectedAttributesValues).flat().join(",");

    fetchProducts(attributesString, true); // Fetch products from the start with new attributes
  };

  const fetchProducts = (attributesValues = null, reset = false) => {
    console.log("attributesValues", attributesValues);

    if (!category) return;

    // if (reset) {
    //   setPage(1);
    //   // setProducts([]);
    //   setHasMore(true);
    // }

    const localProductsCount = products?.length;
    const totalCount = productReducer?.products?.totalCount;

    if (reset || localProductsCount < totalCount) {
      productActions.getProducts({
        pageNumber: reset ? 1 : page,
        pageSize: 10,
        categoryId: category.id,
        attributesValues,
      });
      setPage((prevPage) => prevPage + 1);
    } else {
      setHasMore(false);
    }
  };

  // Get Elements Functions
  const getFilterComponent = () => {
    const filterElementProps = {
      products,
      productsAttributes,
      fetchProducts,
      selectedAttributesValues,
      setSelectedAttributesValues,
    };
    return <ProductsFilterComponent props={filterElementProps} />;
  };

  return (
    <div className="productPage container">
      <h1>{category?.categoryName}</h1>
      {getFilterComponent()}
      <InfiniteScroll dataLength={products.length} next={() => fetchProducts()} hasMore={hasMore}>
        <ProductCardListComponent products={products} />
      </InfiniteScroll>
    </div>
  );
};

const mapStateToProps = (state) => ({
  productReducer: state.productReducer,
  categoryReducer: state.categoryReducer,
});

const mapDispatchToProps = (dispatch) => ({
  categoryActions: bindActionCreators(categoryActions, dispatch),
  productActions: bindActionCreators(productActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductListPage);
