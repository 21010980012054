export const defaultCartItem = {
  productId: "",
  varientId: "",
  productName: "",
  varientName: "",
  quantity: 1,
  price: 0.0,
  imageUrl: "",
  sku: "",
};
