import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate, useParams } from "react-router-dom";
import productActions from "../../../store/actions/product/product.actions";
import categoryActions from "../../../store/actions/product/category.actions";
import propertyActions from "../../../store/actions/product/property.actions";
import AlertService from "../../../services/general/alert.service";
import { ActionTypesEnum } from "../../../enums/general/actinTypesEnum";
import { reducerStateHandler } from "../../../handlers/reducerState.handler";
import { defaultProduct } from "../../../objects/defaults/defaultProduct";
import ProductForm from "../../../components/dashboard/products/ProductForm";
import { buildProductFormData, getFailuerMessage, validateProduct } from "../../../helpers/products/productHelpers";
import AddOrUpdateBaseProductComponent from "../../../components/dashboard/products/addUpdateProcessComponents/AddOrUpdateBaseProductComponent";
import AddOrUpdateProductDescriptionComponent from "../../../components/dashboard/products/addUpdateProcessComponents/AddOrUpdateProductDescriptionComponent";
import AddOrUpdateProductVarientComponent from "../../../components/dashboard/products/addUpdateProcessComponents/AddOrUpdateProductVarientComponent";
import AddOrUpdateProductAttributesComponent from "../../../components/dashboard/products/addUpdateProcessComponents/AddOrUpdateProductAttributesComponent";
import AddOrUpdateProductImagesComponent from "../../../components/dashboard/products/addUpdateProcessComponents/AddOrUpdateProductImagesComponent";

const numberFields = new Set(["stockQuantity", "price"]);

const AddUpdateProductPage = ({ productActions, categoryActions, productReducer, categoryReducer, propertyActions }) => {
  const navigate = useNavigate();
  const { productId } = useParams();
  const [product, setProduct] = useState(defaultProduct);
  const [categories, setCategories] = useState([]);
  const [isUpdateAction, setIsUpdateAction] = useState(false);
  const [selectedProductImage, setSelectedProductImage] = useState();
  const [actionType, setActionType] = useState();

  useEffect(() => {
    categoryActions.getCategories({ pageNumber: -1, pageSize: -1 });
    productActions.getProductAttributes();
  }, []);

  useEffect(() => {
    if (categoryReducer.categories.items) {
      setCategories(categoryReducer.categories.items);
    }
  }, [categoryReducer.categories]);

  useEffect(() => {
    if (productId) {
      setIsUpdateAction(true);
      const selectedProduct = productReducer.products?.items?.find((p) => p.id === productId);
      if (selectedProduct) {
        setProduct({ ...selectedProduct });
      } else {
        AlertService.error("מוצר לא נמצא", "אנה נסה שנית מאוחר יותר");
        navigate(-1);
      }
    }
  }, [productId, productReducer.products, navigate]);

  useEffect(() => {
    if (actionType === ActionTypesEnum.Add || actionType === ActionTypesEnum.Update) {
      if (reducerStateHandler.isStateSuccess(productReducer)) {
        const message = isUpdateAction ? "פריט עודכן בהצלחה" : "פריט נוסף בהצלחה";
        AlertService.success(message);
        setActionType(null);
        navigate(-1);
      } else if (reducerStateHandler.isStateFailuer(productReducer)) {
        const { errorStatusCode } = productReducer.stateInfo;
        const messageObject = getFailuerMessage(errorStatusCode, isUpdateAction);
        AlertService.error(messageObject.title, messageObject.description);
        setActionType(null);
      }
    }
  }, [productReducer.stateInfo, actionType, isUpdateAction, navigate]);

  // ****************************************************

  // ----------  Product Base Functions ---------- //
  const handleBaseProductChanged = (event) => {
    const { name, value } = event.target;
    setProduct({ ...product, [name]: value });
  };

  const handleSubmitBaseProductDetails = async () => {
    const request = { productName: product.productName, categoryId: product.categoryId };

    let response;
    if (isUpdateAction) {
      request.id = product.id;
      response = await productActions.updateBaseProduct(request);
    } else {
      response = await productActions.addBaseProduct(request);
    }

    // VALIDATE RESPONSE AND SHOW MESSAGE
    if (response && response.statusCode === 200 && response.payload) {
      await productActions.getProducts({ pageNumber: 1, pageSize: -1 });
      AlertService.success(`פריט ${isUpdateAction ? "עודכן" : "נוסף"} בהצלחה`);
      navigate(`/dashboard/products/edit/${response.payload}`);
    }
  };
  // --------------------------------------------- //

  // ----------  Product Description Functions ---------- //
  const handleProductDescriptionChanged = (event) => {
    const { name, value } = event.target;
    setProduct({ ...product, description: { ...product.description, [name]: value } });
  };

  const handleSubmitProductDescription = async () => {
    const isUpdateDescriptionAction = product.description.id ? true : false;
    const { descriptionText, subDescriptionText } = product.description;
    const request = { productId: product.id, descriptionText, subDescriptionText };

    let response;
    if (isUpdateDescriptionAction) {
      request.id = product.description.id;
      response = await productActions.updateProductDescription(request);
    } else {
      response = await productActions.addProductDescription(request);
    }

    // VALIDATE RESPONSE AND SHOW MESSAGE
    if (response && response.statusCode === 200) {
      await productActions.getProducts({ pageNumber: 1, pageSize: -1 });
      AlertService.success(`תיאור פריט עודכן בהצלחה`);
    } else {
      AlertService.error(`שגיאה בעדכון תיאור פריט`);
    }
  };
  // --------------------------------------------- //

  // ----------  Get Hlements Functions ---------- //
  const getBaseProductDetalsElement = () => {
    const baseProductProps = { product, categories, isUpdateAction };
    baseProductProps.functions = { handleChange: handleBaseProductChanged, handleSubmit: handleSubmitBaseProductDetails };
    return <AddOrUpdateBaseProductComponent props={baseProductProps} />;
  };

  const getProductDescriptionElement = () => {
    const baseProductProps = { product, categories, isUpdateAction };
    baseProductProps.functions = { handleChange: handleProductDescriptionChanged, handleSubmit: handleSubmitProductDescription };
    return <AddOrUpdateProductDescriptionComponent props={baseProductProps} />;
  };

  const getProductVarientsElement = () => {
    const baseProductProps = { product, isUpdateAction };
    baseProductProps.functions = { handleChange: handleProductDescriptionChanged, handleSubmit: handleSubmitProductDescription };
    return <AddOrUpdateProductVarientComponent props={baseProductProps} />;
  };

  const getProductAttributesElement = () => {
    const baseProductProps = { product };
    baseProductProps.functions = { handleChange: handleProductDescriptionChanged, handleSubmit: handleSubmitProductDescription };
    return <AddOrUpdateProductAttributesComponent props={baseProductProps} />;
  };

  const getProductImagesElement = () => {
    const baseProductProps = { product };
    baseProductProps.functions = { handleChange: handleProductDescriptionChanged, handleSubmit: handleSubmitProductDescription };
    return <AddOrUpdateProductImagesComponent props={baseProductProps} />;
  };
  // ****************************************************

  if (!product) return <div>Loading...</div>;

  return (
    <div className="addOrUpdateProductPageContainer">
      <h2>{isUpdateAction ? "עריכת פריט" : "הוספת פריט"}</h2>
      {getBaseProductDetalsElement()}
      {isUpdateAction && getProductDescriptionElement()}
      {isUpdateAction && getProductVarientsElement()}
      {isUpdateAction && getProductAttributesElement()}
      {isUpdateAction && getProductImagesElement()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  productReducer: state.productReducer,
  categoryReducer: state.categoryReducer,
});

const mapDispatchToProps = (dispatch) => ({
  categoryActions: bindActionCreators(categoryActions, dispatch),
  productActions: bindActionCreators(productActions, dispatch),
  propertyActions: bindActionCreators(propertyActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateProductPage);

// const handleChange = useCallback((event) => {
//   const { name, value } = event.target;
//   setProduct((prevProduct) => ({
//     ...prevProduct,
//     [name]: numberFields.has(name) ? parseFloat(value) : value,
//   }));
// }, []);

// const handleFileChange = useCallback((file, fileDetails) => {
//   setSelectedProductImage(file);
//   setProduct((prevProduct) => ({ ...prevProduct, productImage: fileDetails.url }));
// }, []);

// const handleSubmit = useCallback(
//   (event) => {
//     event.preventDefault();
//     const errorMessage = validateProduct(product);
//     if (!errorMessage) {
//       const formData = buildProductFormData(product, selectedProductImage);
//       if (isUpdateAction) {
//         setActionType(ActionTypesEnum.Update);
//         productActions.updateProduct(product.id, formData);
//       } else {
//         setActionType(ActionTypesEnum.Add);
//         productActions.addProduct(formData);
//       }
//     } else {
//       alert(errorMessage);
//     }
//   },
//   [product, selectedProductImage, isUpdateAction, productActions]
// );

// import React, { useEffect, useState, useCallback } from "react";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import { useNavigate, useParams } from "react-router-dom";
// import productActions from "../../../store/actions/product/product.actions";
// import categoryActions from "../../../store/actions/product/category.actions";
// import propertyActions from "../../../store/actions/product/property.actions";
// import AlertService from "../../../services/general/alert.service";
// import { ActionTypesEnum } from "../../../enums/general/actinTypesEnum";
// import { reducerStateHandler } from "../../../handlers/reducerState.handler";
// import { defaultProduct } from "../../../objects/defaults/defaultProduct";
// import ProductForm from "../../../components/dashboard/products/ProductForm";
// import { buildProductFormData, getFailuerMessage, validateProduct } from "../../../helpers/products/productHelpers";

// const numberFields = new Set(["stockQuantity", "price"]);

// const AddUpdateProductPage = ({ productActions, categoryActions, productReducer, categoryReducer, propertyActions }) => {
//   const navigate = useNavigate();
//   const { productCode } = useParams();
//   const [product, setProduct] = useState(defaultProduct);
//   const [isUpdateAction, setIsUpdateAction] = useState(false);
//   const [selectedProductImage, setSelectedProductImage] = useState();
//   const [actionType, setActionType] = useState();

//   useEffect(() => {
//     categoryActions.getCategories({ pageNumber: -1, pageSize: -1 });
//     propertyActions.getPropertyDefinitions();
//   }, [categoryActions, propertyActions]);

//   useEffect(() => {
//     if (productCode) {
//       setIsUpdateAction(true);
//       const selectedProduct = productReducer.products?.items?.find((p) => p.productCode === productCode);
//       if (selectedProduct) {
//         setProduct({ ...selectedProduct });
//       } else {
//         AlertService.error("מוצר לא נמצא", "אנה נסה שנית מאוחר יותר");
//         navigate(-1);
//       }
//     }
//   }, [productCode, productReducer.products, navigate]);

//   useEffect(() => {
//     if (actionType === ActionTypesEnum.Add || actionType === ActionTypesEnum.Update) {
//       if (reducerStateHandler.isStateSuccess(productReducer)) {
//         const message = isUpdateAction ? "פריט עודכן בהצלחה" : "פריט נוסף בהצלחה";
//         AlertService.success(message);
//         setActionType(null);
//         navigate(-1);
//       } else if (reducerStateHandler.isStateFailuer(productReducer)) {
//         const { errorStatusCode } = productReducer.stateInfo;
//         const messageObject = getFailuerMessage(errorStatusCode, isUpdateAction);
//         AlertService.error(messageObject.title, messageObject.description);
//         setActionType(null);
//       }
//     }
//   }, [productReducer.stateInfo, actionType, isUpdateAction, navigate]);

//   const handleChange = useCallback((event) => {
//     const { name, value } = event.target;
//     setProduct((prevProduct) => ({
//       ...prevProduct,
//       [name]: numberFields.has(name) ? parseFloat(value) : value,
//     }));
//   }, []);

//   const handleFileChange = useCallback((file, fileDetails) => {
//     setSelectedProductImage(file);
//     setProduct((prevProduct) => ({ ...prevProduct, productImage: fileDetails.url }));
//   }, []);

//   const handleSubmit = useCallback(
//     (event) => {
//       event.preventDefault();
//       const errorMessage = validateProduct(product);
//       if (!errorMessage) {
//         const formData = buildProductFormData(product, selectedProductImage);
//         if (isUpdateAction) {
//           setActionType(ActionTypesEnum.Update);
//           productActions.updateProduct(product.id, formData);
//         } else {
//           setActionType(ActionTypesEnum.Add);
//           productActions.addProduct(formData);
//         }
//       } else {
//         alert(errorMessage);
//       }
//     },
//     [product, selectedProductImage, isUpdateAction, productActions]
//   );

//   return (
//     <div className="product-form">
//       <h2>{isUpdateAction ? "Edit Product" : "Add Product"}</h2>
//       <ProductForm product={product} categoryReducer={categoryReducer} handleChange={handleChange} handleFileChange={handleFileChange} handleSubmit={handleSubmit} isUpdateAction={isUpdateAction} />
//     </div>
//   );
// };

// const mapStateToProps = (state) => ({
//   productReducer: state.productReducer,
//   categoryReducer: state.categoryReducer,
// });

// const mapDispatchToProps = (dispatch) => ({
//   categoryActions: bindActionCreators(categoryActions, dispatch),
//   productActions: bindActionCreators(productActions, dispatch),
//   propertyActions: bindActionCreators(propertyActions, dispatch),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateProductPage);
