import { useEffect, useState } from "react";
import shoppingCartService from "../services/local/shoppingCart.service";
import CheckoutOrderDetailsComponent from "../components/checkout/CheckoutOrderDetailsComponent";
import CheckoutOrderSummaryComponent from "../components/checkout/CheckoutOrderSummaryComponent";
import { defaultOrder } from "../objects/defaults/checkout/defaultOrder";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import orderActions from "../store/actions/order/order.actions";
import orderReducer from "../store/reducers/order.reducer";
import { defaultAddress } from "../objects/defaults/checkout/defaultAddress";
import { objectsHelper } from "../helpers/objects.helper";

const numberProperties = ["deliveryType", "totalAmount"];

export const CheckoutPage = ({ orderReducer, orderActions }) => {
  const [cartItemList, setCartItemList] = useState([]);
  const [orderDetails, setOrderDetails] = useState(defaultOrder);
  const [deliveryTypesOptions, setDeliveryTypesOptions] = useState([]);
  const [paymentAddressTypesOptions, setPaymentAddressTypesOptions] = useState([]);
  const [selectedPaymentAddressOption, setSelectedPaymentAddressOption] = useState(1);

  useEffect(() => {
    const items = shoppingCartService.getCartItems();
    if (items) setOrderDetails({ ...orderDetails, orderItems: items });

    fetchDeliveryTypesOptions();
    fetchPaymentAddressTypesOptions();

    // Cleanup function to clear local storage cart when component unmounts
  }, []);

  const fetchDeliveryTypesOptions = async () => {
    try {
      const response = await fetch(`/data/deliveryTypesOptions.json`);
      if (!response.ok) throw new Error("Network response was not ok");

      const options = await response.json();
      setDeliveryTypesOptions(options);
    } catch (error) {}
  };

  const fetchPaymentAddressTypesOptions = async () => {
    try {
      const response = await fetch(`/data/paymentAddressTypesOptions.json`);
      if (!response.ok) throw new Error("Network response was not ok");

      const options = await response.json();
      setPaymentAddressTypesOptions(options);
    } catch (error) {}
  };

  // handle change functions
  const handleChangeOrderDetails = (event) => {
    const { name, value } = event.target;
    if (numberProperties.includes(name)) setOrderDetails({ ...orderDetails, [name]: +value });
    else if (name === "paymentAddressType") {
      setSelectedPaymentAddressOption(+value);
      if (+value === 1) setOrderDetails({ ...orderDetails, billingAddress: null });
      else setOrderDetails({ ...orderDetails, billingAddress: { ...defaultAddress } });
    } else setOrderDetails({ ...orderDetails, [name]: value });
  };

  const handleDeliveryAddressChange = (event) => {
    const { name, value } = event.target;
    if (objectsHelper.hasProperty(orderDetails.shippingAddress.personalDetails, name)) {
      setOrderDetails({ ...orderDetails, shippingAddress: { ...orderDetails.shippingAddress, personalDetails: { ...orderDetails.shippingAddress.personalDetails, [name]: value } } });
    } else {
      setOrderDetails({ ...orderDetails, shippingAddress: { ...orderDetails.shippingAddress, [name]: value } });
    }
  };

  const handlePaymentAddressChange = (event) => {
    const { name, value } = event.target;
    if (objectsHelper.hasProperty(orderDetails.billingAddress.personalDetails, name)) {
      setOrderDetails({ ...orderDetails, billingAddress: { ...orderDetails.billingAddress, personalDetails: { ...orderDetails.billingAddress.personalDetails, [name]: value } } });
    } else {
      setOrderDetails({ ...orderDetails, billingAddress: { ...orderDetails.billingAddress, [name]: value } });
    }
  };

  const calculateDeliveryCost = () => {
    const deliveryDetails = deliveryTypesOptions.find((option) => option.value === orderDetails.deliveryType);
    return deliveryDetails ? deliveryDetails?.price : 0;
  };

  const calculateTotalPrice = () => {
    const totalCartItemsPrice = shoppingCartService.calculateTotalPrice(orderDetails.orderItems);
    const totalDeliveryPrice = calculateDeliveryCost();

    return totalCartItemsPrice + totalDeliveryPrice;
  };

  // ***********************

  const handleValidateDiscountCouponClicked = (event) => {
    event.preventDefault();
    console.log("🚀 ~ CheckoutPage ~ orderDetails:", orderDetails);
  };

  const handlePaymentButtonClicked = async (event) => {
    event.preventDefault();

    const request = { ...orderDetails, deliveryCost: calculateDeliveryCost(), totalAmount: calculateTotalPrice() };
    const createPaymentPageResponse = await orderActions.addOrder(request);
    if (createPaymentPageResponse?.status === 200 && createPaymentPageResponse?.data?.payload?.paymentPageIrl) {
      shoppingCartService.clearCart();
      window.location.href = createPaymentPageResponse.data.payload.paymentPageIrl;
    }
  };

  // get elements functions
  const getCheckoutOrderDetailsElement = () => {
    const elementProps = { orderDetails, deliveryTypesOptions, paymentAddressTypesOptions, selectedPaymentAddressOption };
    elementProps.functions = { handleChangeOrderDetails, handleDeliveryAddressChange, handlePaymentAddressChange };
    return <CheckoutOrderDetailsComponent props={elementProps} />;
  };

  const getCheckoutOrderSummaryElement = () => {
    const elementProps = { orderDetails, deliveryTypesOptions };
    elementProps.functions = { handleChangeOrderDetails, handlePaymentButtonClicked, handleValidateDiscountCouponClicked, calculateTotalPrice, calculateDeliveryCost };
    return <CheckoutOrderSummaryComponent props={elementProps} />;
  };
  // ***********************

  return (
    <div className="checkoutPageContainer container">
      <div className="checkoutHeaderContainer">
        <h1>תשלום</h1>
      </div>

      <div className="checkoutContentContainer">
        {getCheckoutOrderDetailsElement()}
        {getCheckoutOrderSummaryElement()}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    orderReducer: state.orderReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    orderActions: bindActionCreators(orderActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);
