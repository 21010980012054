import { orderActionTypes } from "../actionTypes/order/orderActionTypes";

const initialState = {
  stateInfo: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorStatusCode: null,
  },
  orders: [],
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case orderActionTypes.START_ADD_ORDER:
    case orderActionTypes.START_ATTACH_PAYMENT_TO_ORDER:
      return setStateLoading(state);

    case orderActionTypes.ADD_ORDER_FAILURE:
    case orderActionTypes.ATTACH_PAYMENT_TO_ORDER_SUCCESS:
      return setStateFailure(state, action);

    case orderActionTypes.ADD_ORDER_SUCCESS:
    case orderActionTypes.ATTACH_PAYMENT_TO_ORDER_FAILURE:
      return setStateSuccess(state, action);

    default:
      return state;
  }
};

const setStateLoading = (state) => {
  return {
    ...state,
    stateInfo: {
      isLoading: true,
      isSuccess: false,
      isError: false,
      errorStatusCode: null,
    },
  };
};

const setStateFailure = (state, action) => {
  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: false,
      isError: true,
      errorStatusCode: action.data,
    },
  };
};

const setStateSuccess = (state) => {
  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: true,
      isError: false,
      errorStatusCode: null,
    },
  };
};

export default orderReducer;
