import { orderHttpClient } from "../../installations/axiosInterfaces";

export const addOrder = (data) => {
  return orderHttpClient.post(`/api/order`, data);
};

const orderService = {
  addOrder,
};

export default orderService;
