import React, { createContext, useState, useEffect } from 'react';
import shoppingCartService from '../services/local/shoppingCart.service';

export const CartContext = createContext();


export const ShoppingCartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);

  useEffect(() => {
    const subscription = shoppingCartService.getCartObservable().subscribe(items => {
      setCartItems(items);
      setTotalQuantity(items.reduce((total, item) => total + item.quantity, 0));
    });

    // Clean up the subscription on component unmount
    return () => subscription.unsubscribe();
  }, []);

  return (
    <CartContext.Provider value={{ cartItems, totalQuantity }}>
      {children}
    </CartContext.Provider>
  );
};