import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import productActions from "../../../../store/actions/product/product.actions";
import { useEffect, useState } from "react";
import { defaultPrductAttribute } from "../../../../objects/defaults/product/defaultPrductAttribute";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { genericInputElementsHandler } from "../../../../handlers/genericInputElements.handler";
import SelectInputElementComponent from "../../../Generic/inputElements/SelectInputElementComponent";
import AlertService from "../../../../services/general/alert.service";

export const AddOrUpdateProductAttributesComponent = ({ props, productActions, productReducer }) => {
  const { product } = props;
  const [attributesList, setAttributesList] = useState([]);
  const [varientAttributesList, setVarientAttributesList] = useState([]);

  useEffect(() => {
    if (productReducer.productAttributes) setAttributesList(productReducer.productAttributes);
  }, [productReducer.productAttributes]);

  useEffect(() => {
    if (productReducer.productAttributes) setAttributesList(productReducer.productAttributes);

    const varientAttributes = product.variants.map((variant) => {
      return { varientId: variant.id, varientName: variant.name, attributes: [defaultPrductAttribute, ...variant.variantAttributes] };
    });
    setVarientAttributesList(varientAttributes);
  }, [product]);

  const getAttributeNameById = (attribute) => {
    const attributeDetails = attributesList.find((atr) => atr.id.toUpperCase() === attribute.attributeId.toUpperCase());
    const attributeName = attributeDetails?.attributeName;
    const attributeValue = attributeDetails?.attributeValues.find((atv) => atv.id.toUpperCase() === attribute.attributeValueId.toUpperCase()).value;
    return `${attributeName} - ${attributeValue}`;
  };

  const handleAttributeChanged = (index, event, varientIndex) => {
    const { name, value } = event.target;
    const updatedVarientAttributesList = [...varientAttributesList];

    updatedVarientAttributesList[varientIndex].attributes[index] = {
      ...updatedVarientAttributesList[varientIndex].attributes[index],
      [name]: value,
    };
    setVarientAttributesList(updatedVarientAttributesList);
  };

  const handleSubmitAttribute = async (attribute, index, varientAttributes) => {
    const request = { variantId: varientAttributes.varientId, attributeId: attribute.attributeId, attributeValueId: attribute.attributeValueId };
    const response = await productActions.addProductVarientAttribute(request);
    if (response && response.statusCode === 200) {
      await productActions.getProducts({ pageNumber: 1, pageSize: -1 });
      AlertService.success("מאפיין פריט נוסף בהצלחה");
    } else {
      AlertService.error("שגיאה בהוספת מאפיין פריט");
    }
  };
  const handleDeleteAttribute = async (attribute, index, varientAttributes) => {
    const response = await productActions.deleteProductVarientAttribute(attribute.id);
    if (response && response.statusCode === 200) {
      await productActions.getProducts({ pageNumber: 1, pageSize: -1 });
      AlertService.success("מאפיין פריט נמחק בהצלחה");
    } else {
      AlertService.error("שגיאה במחיקת מאפיין פריט");
    }
  };

  const getAttributeSelectElement = (attribute, index, varientIndex) => {
    const attributesOptions = genericInputElementsHandler.transformDataToSelectObject(attributesList, "id", "attributeName");

    const attributeProps = {
      label: "סוג",
      name: "attributeId",
      value: attribute.attributeId?.toLowerCase(),
      options: attributesOptions,
      onChange: (event) => handleAttributeChanged(index, event, varientIndex),
      required: true,
      disabled: attribute.id ? true : false,
    };
    return <SelectInputElementComponent props={attributeProps} />;
  };

  const getAttributeValueSelectElement = (attribute, index, varientIndex) => {
    const attributesValuesList = attribute.attributeId ? attributesList.find((atr) => atr.id?.toUpperCase() === attribute.attributeId?.toUpperCase())?.attributeValues : [];
    const attributesValueOptions = genericInputElementsHandler.transformDataToSelectObject(attributesValuesList, "id", "value");

    const attributeValueProps = {
      label: "ערך",
      name: "attributeValueId",
      value: attribute.attributeValueId,
      options: attributesValueOptions,
      onChange: (event) => handleAttributeChanged(index, event, varientIndex),
      required: true,
      disabled: attribute.id ? true : false,
    };
    return <SelectInputElementComponent props={attributeValueProps} />;
  };

  const getAttributesAccordionElement = (attribute, attributeIndex, varientAttributes, varientIndex) => {
    return (
      <Accordion key={attributeIndex}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>{attribute.id ? getAttributeNameById(attribute) : "הוספת מאפיין"}</AccordionSummary>
        {getAttributeSelectElement(attribute, attributeIndex, varientIndex)}
        {getAttributeValueSelectElement(attribute, attributeIndex, varientIndex)}

        {!attribute.id && (
          <button
            onClick={() => {
              handleSubmitAttribute(attribute, attributeIndex, varientAttributes);
            }}
          >
            הוסף
          </button>
        )}
        {attribute.id && (
          <button
            onClick={() => {
              handleDeleteAttribute(attribute, attributeIndex, varientAttributes);
            }}
          >
            מחק
          </button>
        )}
      </Accordion>
    );
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>מאפייני פריט</AccordionSummary>
      <AccordionDetails>
        {varientAttributesList.map((varientAttributes, varientIndex) => (
          <Accordion key={varientIndex}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>{varientAttributes.varientName}</AccordionSummary>
            <AccordionDetails>
              <AccordionDetails>{varientAttributes.attributes.map((attribute, attributeIndex) => getAttributesAccordionElement(attribute, attributeIndex, varientAttributes, varientIndex))}</AccordionDetails>
            </AccordionDetails>
          </Accordion>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

const mapStateToProps = (state) => ({
  productReducer: state.productReducer,
});

const mapDispatchToProps = (dispatch) => ({
  productActions: bindActionCreators(productActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddOrUpdateProductAttributesComponent);
