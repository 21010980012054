import { defaultAddress } from "./defaultAddress";
import { defaultGuestUser } from "./defaultGuestUser";

export const defaultOrder = {
  // userType: "Guest", // or "Registered" depending on your use case
  // guestUser: { ...defaultGuestUser },
  userID: null, // will be populated if userType is "Registered"
  orderDate: new Date(),
  totalAmount: 0.0,
  orderItems: [],
  shippingAddress: { ...defaultAddress },
  billingAddress: null,
  deliveryType: 1,
  discountGroupon: "",
};
