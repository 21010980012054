import { productHttpClient } from "../../installations/axiosInterfaces";

export const getCategories = (data) => {
  return productHttpClient.get(`/api/Category`, { params: { pageNumber: data.pageNumber, pageSize: data.pageSize } });
};

export const getCategoryById = (id) => {
  return productHttpClient.get(`/api/Category/${id}`);
};

export const addCategory = (category) => {
  return productHttpClient.post(`/api/Category`, category, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updateCategory = (id, category) => {
  return productHttpClient.put(`/api/Category/${id}`, category, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteCategory = (id) => {
  return productHttpClient.delete(`/api/Category/${id}`);
};

const categoryService = {
  getCategories,
  getCategoryById,
  addCategory,
  updateCategory,
  deleteCategory,
};

export default categoryService;
