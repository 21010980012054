import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";

export const ProductDetailsThumbGalleryComponent = (props) => {
  const { product } = props;
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <div className="productImagesGalleryContainer">
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2"
      >
        {product.images?.map((productFile, index) => (
          <SwiperSlide key={index}>
            <img src={productFile.imageURL} alt={`Product image ${index + 1}`} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper onSwiper={setThumbsSwiper} spaceBetween={10} slidesPerView={4} freeMode={true} watchSlidesProgress={true} modules={[FreeMode, Navigation, Thumbs]} className="mySwiper">
        {product.images?.map((productFile, index) => (
          <SwiperSlide key={index}>
            <img src={productFile.imageURL} alt={`Thumbnail ${index + 1}`} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ProductDetailsThumbGalleryComponent;
