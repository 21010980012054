import { useEffect, useState } from "react";
import { sortProductListOptions } from "../../objects/product/sortProductListOptions";
import SelectInputElementComponent from "../Generic/inputElements/SelectInputElementComponent";
import PriceFilterDialogComponent from "./filterDialogs/PriceFilterDialogComponent";
import FilterFromSelectListDialogComponent from "./filterDialogs/FilterFromSelectListDialogComponent";
import useMobileScreen from "../../hooks/useMobileScreen";

const defaultFragranceList = ["Black Sea", "Down To Earth", "Fire Me Up", "Desire Sky", "Golden Hour"];

export const ProductsFilterComponent = ({ props }) => {
  const { products, productsAttributes, fetchProducts, selectedAttributesValues, setSelectedAttributesValues } = props;
  const [sortBy, setSortBy] = useState();
  const isMobileScreen = useMobileScreen();

  const [sortPriceDialogOpen, setSortPriceDialogOpen] = useState(false);
  const [attributeDialogsOpen, setAttributeDialogsOpen] = useState({}); // State to manage each attribute dialog
  // const [selectedAttributesValues, setSelectedAttributesValues] = useState({}); // State to manage each attribute dialog
  // console.log("🚀 ~ ProductsFilterComponent ~ selectedAttributesValues:", selectedAttributesValues);



  const handleFilterPriceClick = () => {
    setSortPriceDialogOpen(!sortPriceDialogOpen);
  };

  const handleAttributeDialogToggle = (attributeId) => {
    setAttributeDialogsOpen((prevState) => ({
      ...prevState,
      [attributeId]: !prevState[attributeId],
    }));
  };

  const buildSelectDialogOptionList = (attributeValues) => {
    return attributeValues.map((attributeValue) => ({ id: attributeValue.id, value: attributeValue.value }));
  };

  // Function to apply filters
  const applyAttributeFilter = (attributeId, attrubuteValues) => {
    setSelectedAttributesValues({ ...selectedAttributesValues, [attributeId]: attrubuteValues });
  };

  // get element functions

  const getSortBySelectElement = () => {
    const sortElementProps = {
      options: sortProductListOptions,
      name: "sortBy",
      value: sortBy,
      clearBaseDesign: true,
      customStyleClass: "",
    };

    return <SelectInputElementComponent props={sortElementProps} />;
  };

  const getFilterAttributeSelectElement = (attribute) => {
    const dialogProps = { attribute };
    dialogProps.isOpen = attributeDialogsOpen[attribute.id] || false;
    dialogProps.attributeValuesList = buildSelectDialogOptionList(attribute.attributeValues);
    dialogProps.functions = { applyAttributeFilter, onClose: handleAttributeDialogToggle };
    return (
      <div key={attribute.id} className="fragranceFilterContainer">
        <span onClick={() => handleAttributeDialogToggle(attribute.id)}>
          {attribute.attributeName} <i className="fa-solid fa-angle-down"></i>
        </span>
        <FilterFromSelectListDialogComponent props={dialogProps} />
      </div>
    );
  };

  return (
    <div className="filterSortItemsContainer">
      <div className="filterContainer">
        <span>סינון:</span>
        <div className="priceFilterContainer">
          <span onClick={handleFilterPriceClick}>
            מחיר <i className="fa-solid fa-angle-down"></i>
          </span>
          <PriceFilterDialogComponent isOpen={sortPriceDialogOpen} onClose={() => setSortPriceDialogOpen(false)} />
        </div>

        {productsAttributes.map((attribute) => getFilterAttributeSelectElement(attribute))}
      </div>
      <div className="sortContainer">
        <span>מיין לפי:</span>
        {getSortBySelectElement()}
        <span>{products?.length} פריטים</span>
      </div>
    </div>
  );
};

export default ProductsFilterComponent;
