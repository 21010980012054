// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import banner1 from "../../../assets/images/homePageBanners/1.jpg";
import banner2 from "../../../assets/images/homePageBanners/2.jpg";
import banner3 from "../../../assets/images/homePageBanners/3.jpg";
import banner4 from "../../../assets/images/homePageBanners/4.jpg";
import HomePageBannerCardComponent from "./HomePageBannerCardComponent";

export const HomePageBannerComponent = () => {
  return (
    <div className="homePageBannerContainer">
      <Swiper
        className="swiperContainer"
        pagination={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Autoplay]}
      >
        {bannerCards.map((bannerCard, idx) => (
          <SwiperSlide key={idx}>
            <HomePageBannerCardComponent props={bannerCard} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

const bannerCards = [
  {
    image: banner1,
    title: "נרות ריחניים למבצע",
    subtitle: "הנחות עד 50% על כל הנרות הריחניים",
    actionTitle: "קנה עכשיו",
    action: () => {
      /* Action for the first card */
    },
  },
  {
    image: banner2,
    title: "מפיצי ריח לבית",
    subtitle: "מגוון רחב של מפיצי ריח לכל חדרי הבית",
    actionTitle: "לפרטים נוספים",
    action: () => {
      /* Action for the second card */
    },
  },
  {
    image: banner3,
    title: "מארזי מתנה לנרות ריחניים",
    subtitle: "מארזים מושלמים למתנה לכל אירוע",
    actionTitle: "גלה עוד",
    action: () => {
      /* Action for the third card */
    },
  },
  {
    image: banner4,
    title: "מוצרים משלימים לנרות ריחניים",
    subtitle: "מעמדים, מיכלים ועיצובים נוספים לנרות ריחניים",
    actionTitle: "ראה מוצרים",
    action: () => {
      /* Action for the fourth card */
    },
  },
];

export default HomePageBannerComponent;
