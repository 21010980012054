import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, Outlet } from "react-router-dom";

const DashboardPage = ({ props }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="dashboard">
      <div className="sidebar">
        <nav>
          <ul>
            <li>
              <Link to="/dashboard/overview">סקירה כללית</Link>
            </li>
            <li>
              <Link to="/dashboard/categories">קטגוריות</Link>
            </li>
            <li>
              <Link to="/dashboard/products">מוצרים</Link>
            </li>
            <li>
              <Link to="/dashboard/users">משתמשים</Link>
            </li>
            <li>
              <Link to="/dashboard/settings">הגדרות אתר</Link>
            </li>
          </ul>
        </nav>
      </div>
      <div className="main-content">
        <div className="content">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  userReducer: state.userReducer,
  websiteReducer: state.websiteReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    //   categoryActions: bindActionCreators(categoryActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
