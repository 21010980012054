import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { thunk as ReduxThunk } from "redux-thunk";
import productReducer from "./reducers/product/product.reducer";
import categoryReducer from "./reducers/product/category.reducer";
import userReducer from "./reducers/user/user.reducer";
import propertyReducer from "./reducers/product/property.reducer";
import orderReducer from "./reducers/order.reducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  productReducer: productReducer,
  categoryReducer: categoryReducer,
  userReducer: userReducer,
  propertyReducer: propertyReducer,
  orderReducer: orderReducer,
});

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(ReduxThunk)));

export default store;
