import NumberInputElementComponent from "../Generic/inputElements/NumberInputElementComponent";
import SelectInputElementComponent from "../Generic/inputElements/SelectInputElementComponent";
import TextInputElementComponent from "../Generic/inputElements/TextInputElementComponent";

const IsraelCities = [
  { id: 1, label: "נס-ציונה" },
  { id: 2, label: "תל-אביב" },
  { id: 3, label: "רחובות" },
  { id: 4, label: "ראשון-לציון" },
];

const CheckoutAddressDetailsComponent = ({ props }) => {
  const { headerTitle, orderDetails, address } = props;
  const { handleChange } = props.functions;

  // Get Ellements Functions
  const getClientNameInputsElement = () => {
    const firstNameProps = {
      name: "firstName",
      value: address.personalDetails.firstName,
      clearDesgin: true,
      customDesignClass: "paymentDetailsInput",
      placeholder: "שם פרטי*",
      onChange: handleChange,
      required: true,
    };

    const lastNameProps = {
      name: "lastName",
      value: address.personalDetails.lastName,
      clearDesgin: true,
      customDesignClass: "paymentDetailsInput",
      placeholder: "שם משפחה*",
      onChange: handleChange,
      required: true,
    };

    return (
      <div className="clientNameContainer">
        <TextInputElementComponent props={firstNameProps} />
        <TextInputElementComponent props={lastNameProps} />
      </div>
    );
  };

  const getCountryInputElement = () => {
    const inputProps = {
      name: "country",
      value: "ישראל",
      clearDesgin: true,
      customDesignClass: "paymentDetailsInput",
      placeholder: "ישראל",
      onChange: handleChange,
    };

    return <TextInputElementComponent props={inputProps} />;
  };

  const getSelectCityInputElement = () => {
    const citiesOptions = IsraelCities.categories?.items?.map((item) => {
      return { value: item.id, label: item.label };
    });

    const inputProps = {
      name: "city",
      //   label: "שם יישוב",
      value: address.city,
      options: citiesOptions,
      onChange: handleChange,
    };
    return <SelectInputElementComponent props={inputProps} />;
  };

  const getAddressInputElement = () => {
    const inputProps = {
      name: "addressLine1",
      value: address.addressLine1,
      clearDesgin: true,
      customDesignClass: "paymentDetailsInput",
      placeholder: "כתובת רחוב (ללא מספר)",
      onChange: handleChange,
    };

    return <TextInputElementComponent props={inputProps} />;
  };

  const getBuildingNumberInputElement = () => {
    const inputProps = {
      name: "buildingNumber",
      value: address.buildingNumber,
      clearDesgin: true,
      customDesignClass: "paymentDetailsInput",
      placeholder: "מספר דירה ",
      onChange: handleChange,
    };

    return <NumberInputElementComponent props={inputProps} />;
  };

  // const getApartmentNumberInputElement = () => {
  //   const inputProps = {
  //     name: "addressLine2",
  //     value: address.addressLine2,
  //     clearDesgin: true,
  //     customDesignClass: "paymentDetailsInput",
  //     placeholder: "",
  //     onChange: handleChange,
  //   };

  //   return <TextInputElementComponent props={inputProps} />;
  // };

  const getZipCodeInputElement = () => {
    const inputProps = {
      name: "zipCode",
      value: address.zipCode,
      clearDesgin: true,
      customDesignClass: "paymentDetailsInput",
      placeholder: "מיקוד/תא דואר (אופציונלי)",
      onChange: handleChange,
    };

    return <TextInputElementComponent props={inputProps} />;
  };

  const getPhoneInputElement = () => {
    const inputProps = {
      name: "phone",
      value: address.personalDetails.phone,
      clearDesgin: true,
      customDesignClass: "paymentDetailsInput",
      placeholder: "טלפון *",
      onChange: handleChange,
    };

    return <TextInputElementComponent props={inputProps} />;
  };

  const getEmailInputElement = () => {
    const inputProps = {
      name: "email",
      value: address.personalDetails.email,
      clearDesgin: true,
      customDesignClass: "paymentDetailsInput",
      placeholder: "כתובת אימייל *",
      onChange: handleChange,
    };

    return <TextInputElementComponent props={inputProps} />;
  };

  return (
    <div className="paymentDetailsContainer">
      <div className="paymentDetailsHeader">
        <span>{headerTitle}</span>
      </div>
      <div className="paymentDetailsFormComtainer">
        {getClientNameInputsElement()}
        {getCountryInputElement()}
        {getSelectCityInputElement()}
        {getAddressInputElement()}
        {getBuildingNumberInputElement()}
        {/* {getApartmentNumberInputElement()} */}
        {getZipCodeInputElement()}
        {getPhoneInputElement()}
        {getEmailInputElement()}
      </div>
    </div>
  );
};

export default CheckoutAddressDetailsComponent;
