import { defaultGuestUser } from "./defaultGuestUser";

export const defaultAddress = {
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  zipCode: "",
  country: "",
  personalDetails: { ...defaultGuestUser },
};
