import { useEffect, useState } from 'react'
import GenericTableComponent from '../../../components/Generic/GenericTableComponent'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import categoryActions from '../../../store/actions/product/category.actions'
import AlertService from '../../../services/general/alert.service'
import { ActionTypesEnum } from '../../../enums/general/actinTypesEnum'
import { reducerStateHandler } from '../../../handlers/reducerState.handler'
import { genericTableActionsEnum } from '../../../enums/general/genericTableActionsEnum'

export const DashboardCategoryListPage = ({
  categoryActions,
  categoryReducer,
}) => {
  const navigate = useNavigate()
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [actionType, setActionType] = useState()
  const [tableData, setTableData] = useState([])
  const [subTableData, setSubTableData] = useState([])

  useEffect(() => {
    getCategoryList()
  }, [])

  useEffect(() => {
    if (categoryReducer?.categories) {
      setCategories(categoryReducer.categories.items)
    }
  }, [categoryReducer?.categories])

  useEffect(() => {
    if (categories) {
      setTableData(getTableDataLisObject(categories))
      handleSetSubCategories()
    }
  }, [categories])

  useEffect(() => {
    if (actionType === ActionTypesEnum.delete) {
      if (reducerStateHandler.isStateSuccess(categoryReducer)) {
        const message = 'קטגוריה נמחקה בהצלחה'
        AlertService.success(message)
        setActionType(null)
        getCategoryList()
      } else if (reducerStateHandler.isStateFailuer(categoryReducer)) {
        const { errorStatusCode } = categoryReducer.stateInfo
        const messageObject = getFailuerMessage(errorStatusCode)
        AlertService.error(messageObject.title, messageObject.description)
        setActionType(null)
        getCategoryList()
      }
    }
  }, [categoryReducer.stateInfo])

  const getCategoryList = () => {
    categoryActions.getCategories({ pageNumber: 1, pageSize: -1 })
  }

  const handleSetSubCategories = () => {
    const subCategoriesList = extractSubCategories(categories)
    setSubCategories(subCategoriesList)
    setSubTableData(getTableDataLisObject(subCategoriesList))
  }

  const handleDeleteCategory = async (categoryId) => {
    const categorty = categoryReducer.categories.items.find(
      (c) => c.id === categoryId,
    )

    var userChoise = await AlertService.confirm(
      'האם אתה בטוח שברצונך למחוק קטגוריה זו?',
      'שים לב! פעולה זו בלתי הפיכה',
      'כן',
      'לא',
    )
    if (userChoise) {
      setActionType(ActionTypesEnum.delete)
      categoryActions.deleteCategory(categoryId)
    }
  }

  const handleDeleteSubCategory = (categoryId) => {}

  const handleEditSubCategoryClicked = (subCategoryId) => {
    const selectedSubCategory = subCategories.find(
      (sbuCategory) => sbuCategory.id === subCategoryId,
    )
    if (selectedSubCategory) {
      navigate(`edit/${selectedSubCategory.parentCategoryId}/sub/${subCategoryId}`);
    }
  }

  // Helper function
  const getFailuerMessage = (statusCode) => {
    switch (statusCode) {
      default:
        return {
          title: 'שגיאה במחיקת קטגוריה',
          description: 'אנא נסה שנית מאוחר יותר',
        }
    }
  }

  const extractSubCategories = (categories) => {
    let subCategoriesList = []
    if (categories) {
      subCategoriesList = categories.reduce((acc, category) => {
        if (category.subCategories && category.subCategories.length > 0) {
          acc = acc.concat(category.subCategories)
        }
        return acc
      }, [])
      return subCategoriesList
    }
  }

  const getTableDataLisObject = (list) => {
    return list.map((category) => {
      return {
        id: category.id,
        name: category.categoryName,
        image: category.categoryImage,
      }
    })
  }
  // ****** ****** ****** ******

  // Genric Table Actions
  const categoriesTableActions = [
    {
      name: 'delete',
      icon: 'delete',
      type: genericTableActionsEnum.function,
      actionFunction: handleDeleteCategory,
    },
    {
      name: 'update',
      icon: 'update',
      type: genericTableActionsEnum.navigate,
      path: 'edit',
    },
  ]
  const subCategoriesTableActions = [
    {
      name: 'delete',
      icon: 'delete',
      type: genericTableActionsEnum.function,
      actionFunction: handleDeleteSubCategory,
    },
    {
      name: 'update',
      icon: 'update',
      type: genericTableActionsEnum.customNavigate,
      path: 'edit',
      actionFunction: handleEditSubCategoryClicked,
    },
  ]
  // ****** ****** ****** ******

  // Get Helement Functions
  const getMainCategoryElement = () => {
    const element = (
      <div>
        <h2>קטגוריות ראשיות</h2>
        <GenericTableComponent
          data={tableData}
          actions={categoriesTableActions}
        />
      </div>
    )
    return element
  }
  const getSubCategoryElement = () => {
    const element = (
      <div>
        <h2>קטגוריות משנה</h2>
        <GenericTableComponent
          data={subTableData}
          actions={subCategoriesTableActions}
        />
      </div>
    )
    return element
  }
  // ****** ****** ****** ******

  return (
    <div>
      <h1>קטגוריות</h1>
      <button onClick={() => navigate(`add`)}>הוספת קטגוריה</button>
      {getMainCategoryElement()}
      {getSubCategoryElement()}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    categoryReducer: state.categoryReducer,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    categoryActions: bindActionCreators(categoryActions, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardCategoryListPage)
