const transformDataToSelectObject = (data, valueKey, labelKey, childrenKey) => {
  return data?.map((item) => ({
    value: item[valueKey],
    label: item[labelKey],
    subOptions: item[childrenKey] ? transformDataToSelectObject(item[childrenKey], valueKey, labelKey, childrenKey) : [],
  }));
};

export const genericInputElementsHandler = {
  transformDataToSelectObject,
};
