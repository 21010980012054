import { categoryActionTypes } from "../../actionTypes/product/categoriesActionTypes";

const initialState = {
  stateInfo: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorStatusCode: null,
  },
  categories: [],
  category: null,
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case categoryActionTypes.START_GET_CATEGORIES:
    case categoryActionTypes.START_GET_CATEGORY:
    case categoryActionTypes.START_ADD_CATEGORY:
    case categoryActionTypes.START_UPDATE_CATEGORY:
    case categoryActionTypes.START_DELETE_CATEGORY:
      return setStateLoading(state);

    case categoryActionTypes.GET_CATEGORIES_FAILURE:
    case categoryActionTypes.GET_CATEGORY_FAILURE:
    case categoryActionTypes.ADD_CATEGORY_FAILURE:
    case categoryActionTypes.UPDATE_CATEGORY_FAILURE:
    case categoryActionTypes.DELETE_CATEGORY_FAILURE:
      return setStateFailure(state, action);

    case categoryActionTypes.GET_CATEGORIES_SUCCESS:
      return setCategories(state, action);

    case categoryActionTypes.GET_CATEGORY_SUCCESS:
      return setCategory(state, action);

    case categoryActionTypes.ADD_CATEGORY_SUCCESS:
    case categoryActionTypes.UPDATE_CATEGORY_SUCCESS:
    case categoryActionTypes.DELETE_CATEGORY_SUCCESS:
      return setStateSuccess(state);

    default:
      return state;
  }
};

const setStateLoading = (state) => {
  return {
    ...state,
    stateInfo: {
      isLoading: true,
      isSuccess: false,
      isError: false,
      errorStatusCode: null,
    },
  };
};

const setStateFailure = (state, action) => {
  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: false,
      isError: true,
      errorStatusCode: action.data,
    },
  };
};

const setCategories = (state, action) => {
  return {
    ...state,
    categories: action.data,
    stateInfo: {
      isLoading: false,
      isSuccess: true,
      isError: false,
      errorStatusCode: null,
    },
  };
};

const setCategory = (state, action) => {
  return {
    ...state,
    category: action.data,
    stateInfo: {
      isLoading: false,
      isSuccess: true,
      isError: false,
      errorStatusCode: null,
    },
  };
};

const setStateSuccess = (state) => {
  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: true,
      isError: false,
      errorStatusCode: null,
    },
  };
};

export default categoryReducer;
