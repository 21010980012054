import { jwtDecode } from 'jwt-decode'

const decodeUserIdFromJWTToken = (token) => {
  let userId;

  try {
    const decodedToken = jwtDecode(token)
    if (decodedToken) {
      userId = decodedToken.nameid
    }
  } catch {}

  return userId
}

const decodeUserTypeFromJWTToken = (token) => {
  let userType;

  try {
    const decodedToken = jwtDecode(token);
    if (decodedToken) {
      userType = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata'];
    }
  } catch {}

  return userType;
};

export const jwtTokenHandler = {
  decodeUserIdFromJWTToken,
  decodeUserTypeFromJWTToken
}
