import { useContext } from 'react'
import { CartContext } from '../../providers/ShoppingCartProvider'

const ShoppingCartIconComponent = () => {
  const { totalQuantity } = useContext(CartContext)

  return (
    <a href="/shoppingCart" className="cart-icon">
      <i className="fa fa-shopping-cart"></i>
      {totalQuantity > 0 && (
        <span className="cart-bubble">{totalQuantity}</span>
      )}
    </a>
  )
}

export default ShoppingCartIconComponent
