export const HomePageBannerCardComponent = ({ props }) => {
  const { image, title, subtitle, actionTitle, action } = props;

  return (
    <div
      className="homePageBannerCardContainer"
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className="overlay">
        <div className="bannerContentContainer">
          <h1>{title}</h1>
          <h3>{subtitle}</h3>
          <button onClick={action}>{actionTitle}</button>
        </div>
      </div>
    </div>
  );
};

export default HomePageBannerCardComponent;



// export const HomePageBannerCardComponent = ({ props }) => {
//   const { image, title, subtitle, actionTitle, action } = props;

//   return (
//     <div className="homePageBannerCardContainer">
//       <div className="bannerImageContainer">
//         <img src={image} />
//       </div>
//       <div className="bannerContetContainer">
//         <h1>{title}</h1>
//         <h3>{subtitle}</h3>
//         <button onClick={action}>{actionTitle}</button>
//       </div>
//     </div>
//   );
// };

// export default HomePageBannerCardComponent;
