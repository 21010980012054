import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";

import HeaderComponent from "./components/HeaderComponent";
import FooterComponent from "./components/FooterComponent";

import { useEffect, useLayoutEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import userActions from "./store/actions/user/user.actions";
import { jwtTokenHandler } from "./handlers/jwtToken.handler";
import useMobileScreen from "./hooks/useMobileScreen";
import { identityRoleEnum } from "./enums/user/identityRoleEnum";

import { ShoppingCartProvider } from "./providers/ShoppingCartProvider";
import AppRoutes from "./providers/AppRoutingProvider";

const App = ({ userActions, userReducer }) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [isAdministrator, setIsAdministrator] = useState();
  const isMobileScreen = useMobileScreen();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [loggedInUserInfo, setLoggedInUserInfo] = useState();

  useEffect(() => {
    setIsAuthenticated(userReducer?.isAuthenticated);

    if (userReducer.isAuthenticated && userReducer.token) {
      const userId = jwtTokenHandler.decodeUserIdFromJWTToken(userReducer.token);
      const userType = jwtTokenHandler.decodeUserTypeFromJWTToken(userReducer.token);

      if (userType === identityRoleEnum.Administrator) setIsAdministrator(true);

      userActions.getUserInfo(userId);
    }
  }, [userReducer.isAuthenticated]);

  useEffect(() => {
    if (userReducer.userInfo) {
      setLoggedInUserInfo(userReducer.userInfo);
    }
  }, [userReducer.userInfo]);

  const toggleOpenNavMenu = (open) => {
    setIsMenuOpen(open);
  };

  const logout = () => {
    userActions.logout();
    if (isMobileScreen) toggleOpenNavMenu();

    setIsAdministrator(false);
    setIsAuthenticated(false);
  };

  // Get Elements Functions
  const getMainScreenElement = () => {
    let element = (
      <div
        className="mainScreen"
        onClick={() => {
          toggleOpenNavMenu(!isMenuOpen);
        }}
      ></div>
    );

    return isMenuOpen ? element : null;
  };

  const getHeaderElement = () => {
    const props = {
      isAuthenticated,
      isMobileScreen,
      isMenuOpen,
      loggedInUserInfo,
      isAdministrator,
    };
    props.functions = { toggleOpenNavMenu, logout };
    return <HeaderComponent props={props} />;
  };

  return (
    <div className="app-container">
      <Router>
        {getMainScreenElement()}
        <ShoppingCartProvider>{getHeaderElement()}</ShoppingCartProvider>
        <div className="content">
          <AppRoutes />
        </div>
        <FooterComponent />
      </Router>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userReducer: state.userReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
