export const productActionTypes = {
  START_GET_PRODUCTS: "START_GET_PRODUCTS",
  GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
  GET_PRODUCTS_FAILURE: "GET_PRODUCTS_FAILURE",

  START_GET_PRODUCT: "START_GET_PRODUCT",
  GET_PRODUCT_SUCCESS: "GET_PRODUCT_SUCCESS",
  GET_PRODUCT_FAILURE: "GET_PRODUCT_FAILURE",

  START_DELETE_PRODUCT: "START_DELETE_PRODUCT",
  DELETE_PRODUCT_SUCCESS: "DELETE_PRODUCT_SUCCESS",
  DELETE_PRODUCT_FAILURE: "DELETE_PRODUCT_FAILURE",

  // ----------- Base Product Actions ----------- //
  START_ADD_PRODUCT: "START_ADD_PRODUCT",
  ADD_PRODUCT_SUCCESS: "ADD_PRODUCT_SUCCESS",
  ADD_PRODUCT_FAILURE: "ADD_PRODUCT_FAILURE",

  START_UPDATE_PRODUCT: "START_UPDATE_PRODUCT",
  UPDATE_PRODUCT_SUCCESS: "UPDATE_PRODUCT_SUCCESS",
  UPDATE_PRODUCT_FAILURE: "UPDATE_PRODUCT_FAILURE",
  // -------------------------------------------------- //

  // ----------- Product Description Actions ----------- //
  START_ADD_PRODUCT_DESCRIPTION: "START_ADD_PRODUCT_DESCRIPTION",
  ADD_PRODUCT_DESCRIPTION_SUCCESS: "ADD_PRODUCT_DESCRIPTION_SUCCESS",
  ADD_PRODUCT_DESCRIPTION_FAILURE: "ADD_PRODUCT_DESCRIPTION_FAILURE",

  START_UPDATE_PRODUCT_DESCRIPTION: "START_UPDATE_PRODUCT_DESCRIPTION",
  UPDATE_PRODUCT_DESCRIPTION_SUCCESS: "UPDATE_PRODUCT_DESCRIPTION_SUCCESS",
  UPDATE_PRODUCT_DESCRIPTION_FAILURE: "UPDATE_PRODUCT_DESCRIPTION_FAILURE",
  // -------------------------------------------------- //
  // ----------- Product Varients Actions ----------- //
  START_ADD_PRODUCT_VARIENT: "START_ADD_PRODUCT_VARIENT",
  ADD_PRODUCT_VARIENT_SUCCESS: "ADD_PRODUCT_VARIENT_SUCCESS",
  ADD_PRODUCT_VARIENT_FAILURE: "ADD_PRODUCT_VARIENT_FAILURE",

  START_UPDATE_PRODUCT_VARIENT: "START_UPDATE_PRODUCT_VARIENT",
  UPDATE_PRODUCT_VARIENT_SUCCESS: "UPDATE_PRODUCT_VARIENT_SUCCESS",
  UPDATE_PRODUCT_VARIENT_FAILURE: "UPDATE_PRODUCT_VARIENT_FAILURE",

  START_DELETE_PRODUCT_VARIENT: "START_DELETE_PRODUCT_VARIENT",
  DELETE_PRODUCT_VARIENT_SUCCESS: "DELETE_PRODUCT_VARIENT_SUCCESS",
  DELETE_PRODUCT_VARIENT_FAILURE: "DELETE_PRODUCT_VARIENT_FAILURE",

  START_ADD_PRODUCT_VARIENT_ATTRIBUTE: "START_ADD_PRODUCT_VARIENT_ATTRIBUTE",
  ADD_PRODUCT_VARIENT_ATTRIBUTE_SUCCESS: "ADD_PRODUCT_VARIENT_ATTRIBUTE_SUCCESS",
  ADD_PRODUCT_VARIENT_ATTRIBUTE_FAILURE: "ADD_PRODUCT_VARIENT_ATTRIBUTE_FAILURE",

  START_DELETE_PRODUCT_VARIENT_ATTRIBUTE: "START_DELETE_PRODUCT_VARIENT_ATTRIBUTE",
  DELETE_PRODUCT_VARIENT_ATTRIBUTE_SUCCESS: "DELETE_PRODUCT_VARIENT_ATTRIBUTE_SUCCESS",
  DELETE_PRODUCT_VARIENT_ATTRIBUTE_FAILURE: "DELETE_PRODUCT_VARIENT_ATTRIBUTE_FAILURE",
  // -------------------------------------------------- //
  // ----------- Product Attributes Actions ----------- //
  START_GET_PRODUCT_ATTRIBUTE: "START_GET_PRODUCT_ATTRIBUTE",
  GET_PRODUCT_ATTRIBUTE_SUCCESS: "GET_PRODUCT_ATTRIBUTE_SUCCESS",
  GET_PRODUCT_ATTRIBUTE_FAILURE: "GET_PRODUCT_ATTRIBUTE_FAILURE",
  // -------------------------------------------------- //
  // ----------- Product Images Actions ----------- //
  START_ADD_PRODUCT_IMAGE: "START_ADD_PRODUCT_IMAGE",
  ADD_PRODUCT_IMAGE_SUCCESS: "ADD_PRODUCT_IMAGE_SUCCESS",
  ADD_PRODUCT_IMAGE_FAILURE: "ADD_PRODUCT_IMAGE_FAILURE",

  START_DELETE_PRODUCT_IMAGE: "START_DELETE_PRODUCT_IMAGE",
  DELETE_PRODUCT_IMAGE_SUCCESS: "DELETE_PRODUCT_IMAGE_SUCCESS",
  DELETE_PRODUCT_IMAGE_FAILURE: "DELETE_PRODUCT_IMAGE_FAILURE",

  START_SET_PRODUCT_IMAGE_AS_PRIMARY: "START_SET_PRODUCT_IMAGE_AS_PRIMARY",
  SET_PRODUCT_IMAGE_AS_PRIMARY_SUCCESS: "SET_PRODUCT_IMAGE_AS_PRIMARY_SUCCESS",
  SET_PRODUCT_IMAGE_AS_PRIMARY_FAILURE: "SET_PRODUCT_IMAGE_AS_PRIMARY_FAILURE",
  // -------------------------------------------------- //
};
