const getMinProductVarientPrice = (product) => {
  const varientPrices = product.variants.map((varient) => varient.price);
  return Math.min(...varientPrices);
};

const getMaxProductVarientPrice = (product) => {
  const varientPrices = product.variants.map((varient) => varient.price);
  return Math.max(...varientPrices);
};

const groupVariantAttributes = (product, attributesList) => {
  const groupedAttributes = {};

  product.variants.forEach((variant) => {
    variant.variantAttributes.forEach((variantAttribute) => {
      const attributeId = variantAttribute.attributeId;
      const attributeValue = variantAttribute.attributeValue.value;

      if (!groupedAttributes[attributeId]) {
        const attributeDetails = attributesList.find((attr) => attr.id === attributeId);
        groupedAttributes[attributeId] = {
          attributeName: attributeDetails ? attributeDetails.attributeName : "",
          values: new Set(),
        };
      }

      groupedAttributes[attributeId].values.add(attributeValue);
    });
  });

  // Convert Set to Array
  Object.keys(groupedAttributes).forEach((key) => {
    groupedAttributes[key].values = Array.from(groupedAttributes[key].values);
  });

  // Convert groupedAttributes to list of objects
  return Object.keys(groupedAttributes).map((key) => ({
    attributeId: key,
    attributeName: groupedAttributes[key].attributeName,
    values: groupedAttributes[key].values,
  }));
};

export const productHandler = { getMinProductVarientPrice, getMaxProductVarientPrice, groupVariantAttributes };
