import shoppingCartService from "../../services/local/shoppingCart.service";
import TextInputElementComponent from "../Generic/inputElements/TextInputElementComponent";

export const CheckoutOrderSummaryComponent = ({ props }) => {
  const { orderDetails, deliveryTypesOptions } = props;
  const { handleChangeOrderDetails, handlePaymentButtonClicked, handleValidateDiscountCouponClicked, calculateTotalPrice, calculateDeliveryCost } = props.functions;

  // get elements funcitons
  const getDiscountCuponInputElement = () => {
    const props = {
      // label: 'קוד הנחה',
      name: "discountGroupon",
      value: orderDetails.discountGroupon,
      clearDesgin: true,
      customDesignClass: "discountCuponInputContainer",
      placeholder: "קוד קופון",
      onChange: handleChangeOrderDetails,
    };
    return <TextInputElementComponent props={props} />;
  };

  const getCatItemListElement = () => {
    return (
      <div className="cartItemListContainer">
        {orderDetails.orderItems.map((cartItem, idx) => (
          <div key={idx} className="cartItemCardContainer">
            <div className="itemDetailsContainer">
              <div className="imageContainer">
                <img src={cartItem.imageUrl} alt={cartItem.productName} />
                <div className="floatQuantityContainer">
                  <span>{cartItem.quantity}</span>
                </div>
              </div>
              <div className="itemDetails">
                <span>{cartItem.productName}</span>
                {cartItem.varientName && <span>{cartItem.varientName}</span>}
                <span className="price">{cartItem.price} ₪</span>
              </div>
            </div>
            <span>{cartItem.quantity * cartItem.price} ₪</span>
          </div>
        ))}
      </div>
    );
  };

  const getDiscountCuponElement = () => {
    return (
      <div className="couponContainer">
        {getDiscountCuponInputElement()}
        <button className="buttonDesign" onClick={handleValidateDiscountCouponClicked}>
          החל
        </button>
      </div>
    );
  };

  const getPriceSymmaryElement = () => {
    return (
      <div className="pricesSummaryContainer">
        <div className="priceDetailsContainer">
          <span>סה"כ פריטים</span>
          <span>{shoppingCartService.calculateTotalPrice(orderDetails.orderItems)} ₪</span>
        </div>
        {getDeliveryPriceElement()}
        <div className="priceDetailsContainer">
          <span className="bold">סה"כ</span>
          <span className="bold">{`${calculateTotalPrice()} ₪`}</span>
        </div>
      </div>
    );
  };

  const getDeliveryPriceElement = () => {
    const deliveryPrice = calculateDeliveryCost();
    const price = deliveryPrice === 0 ? "חינם" : `${deliveryPrice} ₪`;
    return (
      <div className="priceDetailsContainer">
        <span>משלוח</span>
        <span>{price}</span>
      </div>
    );
  };

  const getPaymentButtonElement = () => {
    return (
      <div className="pricesSummaryContainer ">
        <div className="priceDetailsContainer borderNone">
          <button className="buttonDesign" onClick={handlePaymentButtonClicked}>
            תשלום
          </button>
        </div>
      </div>
    );
  };
  // ************************

  return (
    <div className="orderSummaryContainer">
      <div className="orderSummaryHeader">
        <h3>פרטי הזמנה</h3>
      </div>
      <div className="orderSummaryContentContainer">
        {getCatItemListElement()}
        {getDiscountCuponElement()}
        {getPriceSymmaryElement()}
        {getPaymentButtonElement()}
      </div>
    </div>
  );
};

export default CheckoutOrderSummaryComponent;
