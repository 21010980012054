import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextAreaInputElementComponent from "../../../Generic/inputElements/TextAreaInputElementComponent";

export const AddOrUpdateProductDescriptionComponent = ({ props }) => {
  const { product, isUpdateAction } = props;
  const { handleChange, handleSubmit } = props.functions;

  const getDescriptionInputElement = () => {
    const props = {
      label: "תיאור המוצר",
      name: "descriptionText",
      value: product.description?.descriptionText,
      onChange: handleChange,
    };
    return <TextAreaInputElementComponent props={props} />;
  };

  const getSubDescriptionInputElement = () => {
    const props = {
      label: "תיאור נוסף",
      name: "subDescriptionText",
      value: product.description?.subDescriptionText,
      onChange: handleChange,
    };
    return <TextAreaInputElementComponent props={props} />;
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>פרטים נוספים</AccordionSummary>
      <AccordionDetails>
        <div>
          {getDescriptionInputElement()}
          {getSubDescriptionInputElement()}
          <button onClick={handleSubmit}>עדכן</button>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default AddOrUpdateProductDescriptionComponent;
