import { defaultPrductAttribute } from "./defaultPrductAttribute";

export const defaultProductVarient = {
  id: null,
  productId: "",
  sku: "",
  price: 0.0,
  stockQuantity: 0,
  name: "",
  description: "",
  variantAttributes: [defaultPrductAttribute],
};
