export const NumberInputElementComponent = ({ props }) => {
  // Define default min and max values
  const { label, name, value, onChange, required, min = 0, max = 100000000, clearDesgin, customDesignClass, placeholder } = props;

  return (
    <div className={`${clearDesgin ? "" : "text-element-container"} ${customDesignClass}`}>
      <label>{label}</label>
      <input type="number" name={name} value={value} onChange={onChange} required={required} min={min} max={max} placeholder={placeholder} />
    </div>
  );
};

export default NumberInputElementComponent;
