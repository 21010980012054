import React, { useEffect, useState } from "react";
import GenericTableComponent from "../../../components/Generic/GenericTableComponent";
import { connect } from "react-redux";
import productActions from "../../../store/actions/product/product.actions";
import categoryActions from "../../../store/actions/product/category.actions";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import AlertService from "../../../services/general/alert.service";
import { ActionTypesEnum } from "../../../enums/general/actinTypesEnum";
import { reducerStateHandler } from "../../../handlers/reducerState.handler";
import { genericTableActionsEnum } from "../../../enums/general/genericTableActionsEnum";

const DashboardProductsListPage = ({ productActions, productReducer }) => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [actionType, setActionType] = useState();

  useEffect(() => {
    getProductList();
  }, []);

  useEffect(() => {
    if (productReducer?.products) setProducts(productReducer.products.items);
  }, [productReducer?.products]);

  useEffect(() => {
    if (products) {
      const productsTableData = [];

      products.forEach((product) => {
        productsTableData.push({ id: product.id, name: product.productName, image: product.mainImage });
      });
      setTableData(productsTableData);
    }
  }, [products]);

  useEffect(() => {
    if (actionType === ActionTypesEnum.delete) {
      if (reducerStateHandler.isStateSuccess(productReducer)) {
        const message = "פריט נמחק בהצלחה";
        AlertService.success(message);
        setActionType(null);
        getProductList();
      } else if (reducerStateHandler.isStateFailuer(productReducer)) {
        const { errorStatusCode } = productReducer.stateInfo;
        const messageObject = getFailuerMessage(errorStatusCode);
        AlertService.error(messageObject.title, messageObject.description);
        setActionType(null);
        getProductList();
      }
    }
  }, [productReducer.stateInfo]);

  const getProductList = () => {
    productActions.getProducts({ pageNumber: 1, pageSize: -1 });
  };
  const handleDeleteProduct = async (productId) => {
    // console.log("🚀 ~ handleDeleteProduct ~ productCode:", productCode)
    // const product = productReducer.products.items.find((c) => c.productCode === productCode);

    var userChoise = await AlertService.confirm("האם אתה בטוח שברצונך למחוק פריט זה?", "שים לב! פעולה זו בלתי הפיכה", "כן", "לא");
    if (userChoise) {
      setActionType(ActionTypesEnum.delete);
      productActions.deleteProduct(productId);
    }
  };

  // Helper function
  const getFailuerMessage = (statusCode) => {
    switch (statusCode) {
      default:
        return { title: "שגיאה במחיקת פריט", description: "אנא נסה שנית מאוחר יותר" };
    }
  };

  // Genric Table Actions
  const actions = [
    { name: "delete", icon: "delete", type: genericTableActionsEnum.function, actionFunction: handleDeleteProduct },
    { name: "update", icon: "update", type: genericTableActionsEnum.navigate, path: "edit" },
  ];
  // ****** ****** ****** ******

  return (
    <div>
      <h1>Products</h1>
      <button onClick={() => navigate(`add`)}>הוספת מוצר</button>
      <button onClick={() => navigate(`properties`)}>ניהול מאפיינים</button>
      <GenericTableComponent data={tableData} actions={actions} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    productReducer: state.productReducer,
    categoryReducer: state.categoryReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    categoryActions: bindActionCreators(categoryActions, dispatch),
    productActions: bindActionCreators(productActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardProductsListPage);

// export default DashboardProductsComponent;
