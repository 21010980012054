export const TextAreaInputElementComponent = ({ props }) => {
  const { name, label, value, onChange, required, clearBaseDesign, customStyleClass } = props;
  return (
    <div className={`${clearBaseDesign ? "" : "select-element-container"} ${customStyleClass ? customStyleClass : ""}`}>
      <label>{label}</label>
      <textarea name={name} value={value} onChange={onChange} required={required} />
    </div>
  );
};

export default TextAreaInputElementComponent;
