import { propertiesActionTypes } from '../../actionTypes/product/propertiesActionTypes'

const initialState = {
  stateInfo: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: null,
  },
  propertyDefinitions: [],
}

const propertyReducer = (state = initialState, action) => {
  switch (action.type) {
    case propertiesActionTypes.START_GET_PROPERTY_DEFINITIONS:
      return setStateLoading(state)

    case propertiesActionTypes.GET_PROPERTY_DEFINITIONS_FAILURE:
      return setStateFailure(state, action)

    case propertiesActionTypes.GET_PROPERTY_DEFINITIONS_SUCCESS:
      return setPropertyDefinitions(state, action)

    default:
      return state
  }
}

const setStateLoading = (state) => {
  return {
    ...state,
    stateInfo: {
      isLoading: true,
      isSuccess: false,
      isError: false,
      error: null,
    },
  }
}

const setStateFailure = (state, action) => {
  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: false,
      isError: true,
      error: action.data,
    },
  }
}

const setPropertyDefinitions = (state, action) => {
  return {
    ...state,
    propertyDefinitions: action.data,
    stateInfo: {
      isLoading: false,
      isSuccess: true,
      isError: false,
      error: null,
    },
  }
}

export default propertyReducer
