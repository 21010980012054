import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextAreaInputElementComponent from "../../../Generic/inputElements/TextAreaInputElementComponent";
import { useEffect, useState } from "react";
import { defaultProductVarient } from "../../../../objects/defaults/product/defaultProductVarient";
import TextInputElementComponent from "../../../Generic/inputElements/TextInputElementComponent";
import NumberInputElementComponent from "../../../Generic/inputElements/NumberInputElementComponent";
import { connect } from "react-redux";
import productActions from "../../../../store/actions/product/product.actions";
import { bindActionCreators } from "redux";
import AlertService from "../../../../services/general/alert.service";
const numberFields = new Set(["stockQuantity", "price"]);

export const AddOrUpdateProductVarientComponent = ({ props, productActions, productReducer }) => {
  const { product, isUpdateAction } = props;
  const [varientList, setVarientList] = useState([defaultProductVarient]);

  useEffect(() => {
    setVarientList([defaultProductVarient, ...product.variants]);
  }, [product]);

  const handleVarientChange = (index, event) => {
    const { name, value } = event.target;
    const updatedVarients = [...varientList];
    updatedVarients[index] = { ...updatedVarients[index], [name]: numberFields.has(name) ? parseFloat(value) : value };
    setVarientList(updatedVarients);
  };

  const handleSubmitVarient = async (varient) => {
    let response;
    const isUpdateVarient = varient.id;
    if (isUpdateVarient) {
      response = await productActions.updateProductVarient(varient);
    } else {
      varient.productId = product.id;
      response = await productActions.addProductVarient(varient);
    }

    // VALIDATE RESPONSE AND SHOW MESSAGE
    if (response && response.statusCode === 200) {
      await productActions.getProducts({ pageNumber: 1, pageSize: -1 });
      AlertService.success(`גירסת פריט ${isUpdateVarient ? "עודכנה" : "נוספה"} בהצלחה`);
    } else {
      AlertService.error(`שגיאה ב${isUpdateVarient ? "עדכון" : "הוספת"} גירסת פריט`);
    }
  };

  const handleDeleteVarient = async (varientId) => {
    const response = await productActions.deleteProductVarient(varientId);

    // VALIDATE RESPONSE AND SHOW MESSAGE
    if (response && response.statusCode === 200) {
      await productActions.getProducts({ pageNumber: 1, pageSize: -1 });
      AlertService.success("גירסת פריט נמחקה בהצלחה");
    } else {
      AlertService.error("שגיאה במחיקת גירסת פריט");
    }
  };

  // ----------  Get Hlements Functions ---------- //
  const getVarientNameElement = (varient, index) => {
    const nameProps = {
      label: "שם",
      name: "name",
      value: varient.name,
      onChange: (event) => handleVarientChange(index, event),
    };
    return <TextInputElementComponent props={nameProps} />;
  };

  const getVarientDescriptionElement = (varient, index) => {
    const nameProps = {
      label: "תיאור",
      name: "description",
      value: varient.description,
      onChange: (event) => handleVarientChange(index, event),
    };
    return <TextInputElementComponent props={nameProps} />;
  };

  const getVarientSKUElement = (varient, index) => {
    const nameProps = {
      label: 'מק"ט',
      name: "sku",
      value: varient.sku,
      onChange: (event) => handleVarientChange(index, event),
    };
    return <TextInputElementComponent props={nameProps} />;
  };

  const getVarientStockQuantityElement = (varient, index) => {
    const props = {
      label: "כמות במלאי",
      name: "stockQuantity",
      min: -100000,
      value: varient.stockQuantity,
      onChange: (event) => handleVarientChange(index, event),
    };
    return <NumberInputElementComponent props={props} />;
  };

  const getVarientPriceElement = (varient, index) => {
    const props = {
      label: "מחיר",
      name: "price",
      min: 0,
      value: varient.price,
      onChange: (event) => handleVarientChange(index, event),
    };
    return <NumberInputElementComponent props={props} />;
  };

  const getVarientElement = (varient, index)=>{
    return <Accordion key={index}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}> {varient.id ? varient.name : "הוספת גירסת פריט"}</AccordionSummary>
    <AccordionDetails>
      <div>
        {getVarientNameElement(varient, index)}
        {getVarientDescriptionElement(varient, index)}
        {getVarientSKUElement(varient, index)}
        {getVarientStockQuantityElement(varient, index)}
        {getVarientPriceElement(varient, index)}

        <button onClick={() => { handleSubmitVarient(varient);}}>{varient.id ? "עדכן" : "הוסף"}</button>
        {varient.id && (<button onClick={() => {handleDeleteVarient(varient.id);}}>מחק</button>)}
      </div>
    </AccordionDetails>
  </Accordion>
  }
  // --------------------------------------------- //

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>גרסאות פריט</AccordionSummary>
      <AccordionDetails>
          {varientList.map((varient, index) => (
            getVarientElement(varient, index)
          ))}
      </AccordionDetails>
    </Accordion>
  );
};

const mapStateToProps = (state) => ({
  productReducer: state.productReducer,
});

const mapDispatchToProps = (dispatch) => ({
  productActions: bindActionCreators(productActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddOrUpdateProductVarientComponent);
