import { useCallback, useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import productActions from "../../../../store/actions/product/product.actions";
import FileInputElementComponent from "../../../Generic/inputElements/FileInputElementComponent";
import { fileTypesEnum } from "../../../../enums/fileTypesEnum";
import AlertService from "../../../../services/general/alert.service";

export const AddOrUpdateProductImagesComponent = ({ props, productActions, productReducer }) => {
  const { product } = props;
  const [selectedFile, setSelectedFile] = useState();

  useEffect(() => {}, []);

  useEffect(() => {}, [product]);

  const handleFileChange = useCallback((file, fileDetails) => {
    if (file) setSelectedFile(file);
  }, []);

  const handleAddImageSubmit = async () => {
    const formData = new FormData();
    formData.append("ProductId", product.id);
    formData.append("File", selectedFile);
    const response = await productActions.addProductImage(formData);

    // VALIDATE RESPONSE AND SHOW MESSAGE
    if (response && response.statusCode === 200) {
      await productActions.getProducts({ pageNumber: 1, pageSize: -1 });
      AlertService.success("תמונת פריט נוספה בהצלחה");
    } else {
      AlertService.error("שגיאה בהוספת תמונת פריט");
    }
  };

  const handleRemoveImage = async (imageDetails) => {
    const response = await productActions.deleteProductImage(imageDetails.id);

    // VALIDATE RESPONSE AND SHOW MESSAGE
    if (response && response.statusCode === 200) {
      await productActions.getProducts({ pageNumber: 1, pageSize: -1 });
      AlertService.success("תמונת פריט נמחקה בהצלחה");
    } else {
      AlertService.error("שגיאה במחיקת תמונת פריט");
    }
  };

  const handleSetAsPrimaryImage = async (imageDetails) => {
    const response = await productActions.setProductImageAsPrimaryImage(imageDetails.id);

    // VALIDATE RESPONSE AND SHOW MESSAGE
    if (response && response.statusCode === 200) {
      await productActions.getProducts({ pageNumber: 1, pageSize: -1 });
      AlertService.success("תמונת פריט נמחקה בהצלחה");
    } else {
      AlertService.error("שגיאה במחיקת תמונת פריט");
    }
  };

  const getAddImageElement = () => {
    const imageProps = {
      label: "תמונת פריט ראשית",
      name: "productImage",
      value: product.productImage,
      fileType: fileTypesEnum.image,
      onChange: handleFileChange,
    };
    return (
      <div>
        <FileInputElementComponent props={imageProps} />{" "}
        <button
          onClick={() => {
            handleAddImageSubmit();
          }}
        >
          הוסף
        </button>
      </div>
    );
  };

  const getImageListElement = () => {
    return (
      <div className="galleryContainer">
        {product?.images?.map((imageDetails, idx) => (
          <div key={idx} className="imageContainer">
            <img src={imageDetails.imageURL} alt={product.productName} />
            <button className="removeButton" onClick={() => handleRemoveImage(imageDetails)}>
              <i className="fa-solid fa-xmark"></i>
            </button>
            {!imageDetails.isPrimary && (
              <button className="primaryButton" onClick={() => handleSetAsPrimaryImage(imageDetails)}>
                קבע כברירת מחדל
              </button>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>תמונות</AccordionSummary>
      <AccordionDetails>
        {getAddImageElement()}
        {getImageListElement()}
      </AccordionDetails>
    </Accordion>
  );
};

const mapStateToProps = (state) => ({
  productReducer: state.productReducer,
});

const mapDispatchToProps = (dispatch) => ({
  productActions: bindActionCreators(productActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddOrUpdateProductImagesComponent);
