import SelectInputElementComponent from "../../../../components/Generic/inputElements/SelectInputElementComponent";
import TextInputElementComponent from "../../../../components/Generic/inputElements/TextInputElementComponent";

export const AddUpdateProductPropertyPage = () => {
  const handleChange = () => {};

  const getPropertyNameElement = () => {
    const props = {
      label: "שם מאפיין",
      name: "name",
      value: "",
      onChange: handleChange,
    };
    return <TextInputElementComponent props={props} />;
  };

  const getPropertyTypeSelectEelment = () => {
    const props = {
      label: "סוג מאפיין",
      name: "parentCategoryId",
      value: "string",
      options: propertiesTypes,
      onChange: handleChange,
    };
    return <SelectInputElementComponent props={props} />;
  };

  return (
    <div>
      {getPropertyNameElement()}
      {getPropertyTypeSelectEelment()}
    </div>
  );
};

const propertiesTypes = [
  { value: "string", label: "טקסט" },
  { value: "number", label: "מספר" },
  { value: "array", label: "רשימת אפשרויות" },
  { value: "boolean", label: "כן/לא" },
];

export default AddUpdateProductPropertyPage;
