import propertyService from '../../../services/product/property.service'
import { propertiesActionTypes } from '../../actionTypes/product/propertiesActionTypes'

export const getPropertyDefinitions = () => async (dispatch) => {
  try {
    dispatch({ type: propertiesActionTypes.START_GET_PROPERTY_DEFINITIONS })

    const response = await propertyService.getPropertyDefinitions()
    dispatch({
      type: propertiesActionTypes.GET_PROPERTY_DEFINITIONS_SUCCESS,
      data: response.data?.payload,
    })
  } catch (ex) {
    console.log('getPropertyDefinitions error: ' + ex.message)
    dispatch({
      type: propertiesActionTypes.GET_PROPERTY_DEFINITIONS_FAILURE,
      data: ex.response?.data,
    })
  }
}

const propertyActions = {
  getPropertyDefinitions,
}

export default propertyActions
