import { useState } from "react";

const PriceFilterDialogComponent = ({ isOpen, onClose }) => {
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");

  const handleMinPriceChange = (e) => {
    setMinPrice(e.target.value);
  };

  const handleMaxPriceChange = (e) => {
    setMaxPrice(e.target.value);
  };

  const handleFilterClick = () => {
    // Add your filter logic here
    onClose();
  };

  return (
    isOpen && (
      <div className="priceFilterDialogContainer">
        <div className="dialogHeaderContainer">
          <span>המחיר הגבוה ביותר</span>
          <button onClick={onClose}>איפוס</button>
        </div>
        <div className="priceRangeContainer">
          <div className="inputContainer">
            <input type="number" placeholder="ממחיר" value={minPrice} onChange={handleMinPriceChange} /> ₪
          </div>
          <div className="inputContainer">
            <input type="number" placeholder="עד מחיר" value={maxPrice} onChange={handleMaxPriceChange} /> ₪
          </div>
        </div>
        <div className="actionContainer" onClick={handleFilterClick}>
          <button>סנן</button>
        </div>
      </div>
    )
  );
};

export default PriceFilterDialogComponent;
