import { orderHttpClient } from "../../installations/axiosInterfaces";

export const attachPaymentToOrder = (data) => {
  return orderHttpClient.post(`/api/payment/AttachPayment`, data);
};

const paymentService = {
  attachPaymentToOrder,
};

export default paymentService;
