import { BehaviorSubject } from "rxjs";

// Add an item to the cart
const addItem = (data) => {
  const cartItems = getCartItems();
  cartItems.push(data);
  localStorage.setItem(shoppingCartKey, JSON.stringify(cartItems));
  cartSubject.next(cartItems); // Emit the updated cart items
};

// Get all items from the cart
const getCartItems = () => {
  let cartItems = [];
  const cartItemsStr = localStorage.getItem(shoppingCartKey);
  if (cartItemsStr) cartItems = JSON.parse(cartItemsStr);
  return cartItems;
};

// Remove an item from the cart by its id
const removeItem = (id) => {
  let cartItems = getCartItems();
  cartItems = cartItems.filter((item) => item.productId !== id);
  localStorage.setItem(shoppingCartKey, JSON.stringify(cartItems));
  cartSubject.next(cartItems); // Emit the updated cart items
};

const removeItemByVarientId = (id, varientId) => {
  let cartItems = getCartItems();
  cartItems = cartItems.filter((item) => !(item.productId === id && item.varientId === varientId));
  
  localStorage.setItem(shoppingCartKey, JSON.stringify(cartItems));
  cartSubject.next(cartItems); // Emit the updated cart items
};

// Update the quantity of an item in the cart
const updateItemQuantity = (id, quantity) => {
  const cartItems = getCartItems();
  const itemIndex = cartItems.findIndex((item) => item.productId === id);
  if (itemIndex > -1) {
    cartItems[itemIndex].quantity = quantity;
    localStorage.setItem(shoppingCartKey, JSON.stringify(cartItems));
    cartSubject.next(cartItems); // Emit the updated cart items
  }
};

const updateItemQuantityByVarient = (id, varientId, quantity) => {
  const cartItems = getCartItems();
  const itemIndex = cartItems.findIndex((item) => item.productId === id && item.varientId === varientId);
  if (itemIndex > -1) {
    cartItems[itemIndex].quantity = quantity;
    localStorage.setItem(shoppingCartKey, JSON.stringify(cartItems));
    cartSubject.next(cartItems); // Emit the updated cart items
  }
};

// Clear all items from the cart
const clearCart = () => {
  localStorage.removeItem(shoppingCartKey);
  cartSubject.next([]); // Emit an empty array
};

// Check if an item exists in the cart by its id
const itemExists = (id) => {
  const cartItems = getCartItems();
  return cartItems.some((item) => item.productId === id);
};

const getCartItemById = (id) => {
  const cartItems = getCartItems();
  return cartItems.find((item) => item.productId === id);
};

const getCartItemByIdAndVarientId = (productId, varientId) => {
  const cartItems = getCartItems();
  return cartItems.find((item) => item.productId === productId && item.varientId === varientId);
};

// Get the cart observable
const getCartObservable = () => {
  return cartSubject.asObservable();
};

const calculateTotalPrice = (cartItemList) => {
  const totalCalculatedPrice = cartItemList.reduce((total, item) => {
    return total + item.quantity * item.price;
  }, 0);

  return totalCalculatedPrice;
};

const shoppingCartService = {
  addItem,
  getCartItems,
  getCartItemByIdAndVarientId,
  removeItem,
  removeItemByVarientId,
  updateItemQuantity,
  updateItemQuantityByVarient,
  clearCart,
  itemExists,
  getCartItemById,
  getCartObservable,
  calculateTotalPrice, // Add this line
};

const shoppingCartKey = "cart";
const cartSubject = new BehaviorSubject(getCartItems());

export default shoppingCartService;
