import { connect } from "react-redux";
import userActions from "../store/actions/user/user.actions";
import { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { useLocation, useNavigate } from "react-router-dom";

const LoginRegisterPage = ({ userActions, userReducer }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isLogin = location.pathname === "/login";

  const [credentials, setCredentials] = useState({ firstName: "", lastName: "", email: "", password: "" });
  // const [actionType, setActionType] = useState();

  useEffect(() => {
    if (userReducer.stateInfo.isSuccess && userReducer.isAuthenticated) {
      navigate("/");
    }
  }, [userReducer]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isLogin) {
      userActions.login({ email: credentials.email, password: credentials.password });
    } else {
      userActions.register(credentials);
    }
  };

  const handleRouteChange = () => {
    if (isLogin) {
      navigate("/register");
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="auth-page">
      <h2>{isLogin ? "Login" : "Register"}</h2>
      <form onSubmit={handleSubmit}>
        {!isLogin && (
          <div>
            <div>
              <label>First Name</label>
              <input type="text" name="firstName" value={credentials.firstName} onChange={handleInputChange} required />
            </div>
            <div>
              <label>Last Name</label>
              <input type="text" name="lastName" value={credentials.lastName} onChange={handleInputChange} required />
            </div>
          </div>
        )}
        <div>
          <label>Email</label>
          <input type="email" name="email" value={credentials.email} onChange={handleInputChange} required />
        </div>
        <div>
          <label>Password</label>
          <input type="password" name="password" value={credentials.password} onChange={handleInputChange} required />
        </div>
        {/* <button type="submit" disabled={auth.loading}> */}
        <button type="submit">{isLogin ? "Login" : "Register"}</button>
      </form>
      {/* {auth.error && <p className="error">{auth.error}</p>} */}
      <button type="button" onClick={handleRouteChange}>
        {isLogin ? "Not registered? Register now" : "Registered? Login now"}
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userReducer: state.userReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginRegisterPage);
