import productService from "../../../services/product/product.service";
import { productActionTypes } from "../../actionTypes/product/productsActionTypes";

export const getProducts = (data) => async (dispatch) => {
  let response;
  try {
    dispatch({ type: productActionTypes.START_GET_PRODUCTS });

    response = await productService.getProducts(data);
    dispatch({
      type: productActionTypes.GET_PRODUCTS_SUCCESS,
      data: response.data?.payload,
    });
  } catch (ex) {
    console.log("getProducts error: " + ex.message);
    dispatch({
      type: productActionTypes.GET_PRODUCTS_FAILURE,
      data: ex.response?.data,
    });
  }
};

export const getProductById = (id) => async (dispatch) => {
  try {
    dispatch({ type: productActionTypes.START_GET_PRODUCT });

    const response = await productService.getProductById(id);
    dispatch({
      type: productActionTypes.GET_PRODUCT_SUCCESS,
      data: response.data?.payload,
    });
  } catch (ex) {
    console.log("getProductById error: " + ex.message);
    dispatch({
      type: productActionTypes.GET_PRODUCT_FAILURE,
      data: ex.response?.data,
    });
  }
};

export const getProductByProductCode = (data) => async (dispatch) => {
  try {
    dispatch({ type: productActionTypes.START_GET_PRODUCT });

    const response = await productService.getProductByProductCode(data);
    dispatch({
      type: productActionTypes.GET_PRODUCT_SUCCESS,
      data: response.data?.payload,
    });
  } catch (ex) {
    console.log("getProductById error: " + ex.message);
    dispatch({
      type: productActionTypes.GET_PRODUCT_FAILURE,
      data: ex.response?.data,
    });
  }
};

export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch({ type: productActionTypes.START_DELETE_PRODUCT });

    const response = await productService.deleteProduct(id);
    dispatch({
      type: productActionTypes.DELETE_PRODUCT_SUCCESS,
      data: id,
    });
  } catch (ex) {
    console.log("deleteProduct error: " + ex.message);
    dispatch({
      type: productActionTypes.DELETE_PRODUCT_FAILURE,
      data: ex.response?.data,
    });
  }
};

// ----------- Base Product Actions ----------- //
export const addBaseProduct = (product) => async (dispatch) => {
  let response;
  try {
    dispatch({ type: productActionTypes.START_ADD_PRODUCT });

    response = await productService.addBaseProduct(product);
    response = response.data;
    dispatch({
      type: productActionTypes.ADD_PRODUCT_SUCCESS,
      data: response,
    });
  } catch (ex) {
    console.log("addBaseProduct error: " + ex.message);
    dispatch({
      type: productActionTypes.ADD_PRODUCT_FAILURE,
      data: ex.response?.data,
    });
  }

  return response;
};
export const updateBaseProduct = (product) => async (dispatch) => {
  let response;
  try {
    dispatch({ type: productActionTypes.START_UPDATE_PRODUCT });

    response = await productService.updateBaseProduct(product);
    response = response.data;
    dispatch({
      type: productActionTypes.UPDATE_PRODUCT_SUCCESS,
      data: response,
    });
  } catch (ex) {
    console.log("updateBaseProduct error: " + ex.message);
    dispatch({
      type: productActionTypes.UPDATE_PRODUCT_FAILURE,
      data: ex.response?.data,
    });
  }

  return response;
};
// -------------------------------------------------- //
// ----------- Product Description Actions ----------- //
export const addProductDescription = (request) => async (dispatch) => {
  let response;
  try {
    dispatch({ type: productActionTypes.START_ADD_PRODUCT_DESCRIPTION });

    response = await productService.addProductDescription(request);
    console.log("🚀 ~ addProductDescription ~ response:", response);
    response = response.data;
    dispatch({
      type: productActionTypes.ADD_PRODUCT_DESCRIPTION_SUCCESS,
      data: response,
    });
  } catch (ex) {
    console.log("addProductDescription error: " + ex.message);
    dispatch({
      type: productActionTypes.ADD_PRODUCT_DESCRIPTION_FAILURE,
      data: ex.response?.data,
    });
  }

  return response;
};
export const updateProductDescription = (request) => async (dispatch) => {
  let response;
  try {
    dispatch({ type: productActionTypes.START_UPDATE_PRODUCT_DESCRIPTION });

    response = await productService.updateProductDescription(request);
    response = response.data;
    dispatch({
      type: productActionTypes.UPDATE_PRODUCT_DESCRIPTION_SUCCESS,
      data: response,
    });
  } catch (ex) {
    console.log("addBaseProduct error: " + ex.message);
    dispatch({
      type: productActionTypes.UPDATE_PRODUCT_DESCRIPTION_FAILURE,
      data: ex.response?.data,
    });
  }

  return response;
};
// -------------------------------------------------- //
// ----------- Product Varients Actions ----------- //
export const addProductVarient = (request) => async (dispatch) => {
  let response;
  try {
    dispatch({ type: productActionTypes.START_ADD_PRODUCT_VARIENT });

    response = await productService.addProductVarient(request);
    response = response.data;
    dispatch({
      type: productActionTypes.ADD_PRODUCT_VARIENT_SUCCESS,
      data: response,
    });
  } catch (ex) {
    console.log("addProductVarient error: " + ex.message);
    dispatch({
      type: productActionTypes.ADD_PRODUCT_VARIENT_FAILURE,
      data: ex.response?.data,
    });
  }

  return response;
};
export const deleteProductVarient = (request) => async (dispatch) => {
  let response;
  try {
    dispatch({ type: productActionTypes.START_DELETE_PRODUCT_VARIENT });

    response = await productService.deleteProductVarient(request);
    response = response.data;
    dispatch({
      type: productActionTypes.DELETE_PRODUCT_VARIENT_SUCCESS,
      data: response,
    });
  } catch (ex) {
    console.log("deleteProductVarient error: " + ex.message);
    dispatch({
      type: productActionTypes.DELETE_PRODUCT_VARIENT_FAILURE,
      data: ex.response?.data,
    });
  }

  return response;
};
export const updateProductVarient = (request) => async (dispatch) => {
  let response;
  try {
    dispatch({ type: productActionTypes.START_UPDATE_PRODUCT_VARIENT });

    response = await productService.updateProductVarient(request);
    response = response.data;
    dispatch({
      type: productActionTypes.UPDATE_PRODUCT_VARIENT_SUCCESS,
      data: response,
    });
  } catch (ex) {
    console.log("updateProductVarient error: " + ex.message);
    dispatch({
      type: productActionTypes.UPDATE_PRODUCT_VARIENT_FAILURE,
      data: ex.response?.data,
    });
  }

  return response;
};
export const addProductVarientAttribute = (request) => async (dispatch) => {
  let response;
  try {
    dispatch({ type: productActionTypes.START_ADD_PRODUCT_VARIENT_ATTRIBUTE });

    response = await productService.addProductVarientAttribute(request);
    response = response.data;
    dispatch({
      type: productActionTypes.ADD_PRODUCT_VARIENT_ATTRIBUTE_SUCCESS,
      data: response,
    });
  } catch (ex) {
    console.log("addProductVarientAttribute error: " + ex.message);
    dispatch({
      type: productActionTypes.ADD_PRODUCT_VARIENT_ATTRIBUTE_FAILURE,
      data: ex.response?.data,
    });
  }

  return response;
};
export const deleteProductVarientAttribute = (request) => async (dispatch) => {
  let response;
  try {
    dispatch({ type: productActionTypes.START_DELETE_PRODUCT_VARIENT_ATTRIBUTE });

    response = await productService.deleteProductVarientAttribute(request);
    response = response.data;
    dispatch({
      type: productActionTypes.DELETE_PRODUCT_VARIENT_ATTRIBUTE_SUCCESS,
      data: response,
    });
  } catch (ex) {
    console.log("deleteProductVarientAttribute error: " + ex.message);
    dispatch({
      type: productActionTypes.DELETE_PRODUCT_VARIENT_ATTRIBUTE_FAILURE,
      data: ex.response?.data,
    });
  }

  return response;
};
// -------------------------------------------------- //
// ----------- Product Attributes Actions ----------- //
export const getProductAttributes = () => async (dispatch) => {
  let response;
  try {
    dispatch({ type: productActionTypes.START_GET_PRODUCT_ATTRIBUTE });

    response = await productService.getProductAttributes();
    dispatch({
      type: productActionTypes.GET_PRODUCT_ATTRIBUTE_SUCCESS,
      data: response.data?.payload,
    });
  } catch (ex) {
    console.log("getProductAttributes error: " + ex.message);
    dispatch({
      type: productActionTypes.GET_PRODUCT_ATTRIBUTE_FAILURE,
      data: ex.response?.data,
    });
  }
};
// -------------------------------------------------- //
// ----------- Product Images Actions ----------- //
export const addProductImage = (request) => async (dispatch) => {
  let response;
  try {
    dispatch({ type: productActionTypes.START_ADD_PRODUCT_IMAGE });

    response = await productService.addProductImage(request);
    response = response.data;
    dispatch({
      type: productActionTypes.ADD_PRODUCT_IMAGE_SUCCESS,
      data: response,
    });
  } catch (ex) {
    console.log("addProductImage error: " + ex.message);
    dispatch({
      type: productActionTypes.ADD_PRODUCT_IMAGE_FAILURE,
      data: ex.response?.data,
    });
  }
  return response;
};
export const setProductImageAsPrimaryImage = (request) => async (dispatch) => {
  let response;
  try {
    dispatch({ type: productActionTypes.START_SET_PRODUCT_IMAGE_AS_PRIMARY });

    response = await productService.setProductImageAsPrimaryImage(request);
    response = response.data;
    dispatch({
      type: productActionTypes.SET_PRODUCT_IMAGE_AS_PRIMARY_SUCCESS,
      data: response,
    });
  } catch (ex) {
    console.log("setProductImageAsPrimaryImage error: " + ex.message);
    dispatch({
      type: productActionTypes.SET_PRODUCT_IMAGE_AS_PRIMARY_FAILURE,
      data: ex.response?.data,
    });
  }
  return response;
};
export const deleteProductImage = (request) => async (dispatch) => {
  let response;
  try {
    dispatch({ type: productActionTypes.START_DELETE_PRODUCT_IMAGE });

    response = await productService.deleteProductImage(request);
    response = response.data;
    dispatch({
      type: productActionTypes.DELETE_PRODUCT_IMAGE_SUCCESS,
      data: response,
    });
  } catch (ex) {
    console.log("deleteProductImage error: " + ex.message);
    dispatch({
      type: productActionTypes.DELETE_PRODUCT_IMAGE_FAILURE,
      data: ex.response?.data,
    });
  }

  return response;
};
// -------------------------------------------------- //

const productActions = {
  getProducts,
  getProductById,
  getProductByProductCode,
  deleteProduct,

  // ----------- Base Product Actions ----------- //
  addBaseProduct,
  updateBaseProduct,
  // -------------------------------------------------- //
  // ----------- Product Description Actions ----------- //
  addProductDescription,
  updateProductDescription,
  // -------------------------------------------------- //
  // ----------- Product Varients Actions ----------- //
  addProductVarient,
  updateProductVarient,
  deleteProductVarient,
  addProductVarientAttribute,
  deleteProductVarientAttribute,
  // -------------------------------------------------- //
  // ----------- Product Varients Actions ----------- //
  getProductAttributes,
  // -------------------------------------------------- //
  // ----------- Product Images Actions ----------- //
  addProductImage,
  setProductImageAsPrimaryImage,
  deleteProductImage,
  // -------------------------------------------------- //
};

export default productActions;
