import { productHttpClient } from "../../installations/axiosInterfaces";

export const getProducts = (data) => {
  // return productHttpClient.get(`/api/Product`, { params: { pageNumber: data.pageNumber, pageSize: data.pageSize } });
  return productHttpClient.get(`/api/Product`, { params: { ...data } });
};

export const getProductById = (id) => {
  return productHttpClient.get(`/api/Product/${id}`);
};

export const getProductByProductCode = (data) => {
  return productHttpClient.get(`/api/Product/GetProductByCode/${data}`);
};

export const updateProduct = (id, product) => {
  return productHttpClient.put(`/api/Product/${id}`, product, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteProduct = (id) => {
  return productHttpClient.delete(`/api/Product/${id}`);
};

// ----------- Base Description Actions ----------- //
export const addBaseProduct = (product) => {
  return productHttpClient.post(`/api/Product`, product);
};
export const updateBaseProduct = (product) => {
  return productHttpClient.put(`/api/Product`, product);
};
// -------------------------------------------------- //
// ----------- Product Description Actions ----------- //
export const addProductDescription = (product) => {
  return productHttpClient.post(`/api/ProductDescription`, product);
};
export const updateProductDescription = (product) => {
  return productHttpClient.put(`/api/ProductDescription`, product);
};
// -------------------------------------------------- //
// ----------- Product Varients Actions ----------- //
export const addProductVarient = (product) => {
  return productHttpClient.post(`/api/ProductVariant`, product);
};
export const updateProductVarient = (product) => {
  return productHttpClient.put(`/api/ProductVariant`, product);
};
export const deleteProductVarient = (id) => {
  return productHttpClient.delete(`/api/ProductVariant/${id}`);
};
export const addProductVarientAttribute = (data) => {
  return productHttpClient.post(`/api/ProductVariant/Attribute`, data);
};
export const deleteProductVarientAttribute = (id) => {
  return productHttpClient.delete(`/api/ProductVariant/Attribute/${id}`);
};
// -------------------------------------------------- //
// ----------- Product Attributes Actions ----------- //
export const getProductAttributes = () => {
  return productHttpClient.get(`/api/ProductAttribute`);
};
// -------------------------------------------------- //
// ----------- Product Images Actions ----------- //
export const addProductImage = (data) => {
  return productHttpClient.post(`/api/ProductImage`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const setProductImageAsPrimaryImage = (imageId) => {
  return productHttpClient.post(`/api/ProductImage/SetPrimaryImage/${imageId}`);
};
export const deleteProductImage = (imageId) => {
  return productHttpClient.delete(`/api/ProductImage/${imageId}`);
};
// -------------------------------------------------- //

const productService = {
  getProducts,
  getProductById,
  getProductByProductCode,
  updateProduct,
  deleteProduct,

  // ----------- Base Product Actions ----------- //
  addBaseProduct,
  updateBaseProduct,
  // -------------------------------------------------- //
  // ----------- Product Description Actions ----------- //
  addProductDescription,
  updateProductDescription,
  // -------------------------------------------------- //
  // ----------- Product Varients Actions ----------- //
  addProductVarient,
  updateProductVarient,
  deleteProductVarient,
  addProductVarientAttribute,
  deleteProductVarientAttribute,
  // -------------------------------------------------- //
  // ----------- Product Attributes Actions ----------- //
  getProductAttributes,
  // -------------------------------------------------- //
  // ----------- Product Images Actions ----------- //
  addProductImage,
  setProductImageAsPrimaryImage,
  deleteProductImage,
  // -------------------------------------------------- //
};

export default productService;
