import userService from '../../../services/user/user.service'
import { userActionTypes } from '../../actionTypes/user/userActionTypes'

export const login = (credentials) => async (dispatch) => {
  try {
    dispatch({ type: userActionTypes.START_LOGIN })

    const response = await userService.login(credentials)
    const token = response.data.payload

    dispatch({
      type: userActionTypes.LOGIN_SUCCESS,
      data: token,
    })

    // Save the token to local storage
    localStorage.setItem('jwtToken', token)
  } catch (ex) {
    console.log('Login error: ' + ex.message)
    dispatch({ type: userActionTypes.LOGIN_FAILURE, error: ex.response?.data })
  }
}

export const register = (userDetails) => async (dispatch) => {
  try {
    dispatch({ type: userActionTypes.START_REGISTER })

    const response = await userService.register(userDetails)
    const token = response.data.payload

    dispatch({
      type: userActionTypes.REGISTER_SUCCESS,
      data: token,
    })

    // Save the token to local storage
    localStorage.setItem('jwtToken', token)
  } catch (ex) {
    console.log('Register error: ' + ex.message)
    dispatch({
      type: userActionTypes.REGISTER_FAILURE,
      error: ex.response?.data,
    })
  }
}

export const logout = () => (dispatch) => {
  localStorage.removeItem('jwtToken')
  dispatch({ type: userActionTypes.LOGOUT })
}

export const getUserInfo = (data) => async (dispatch) => {
  try {
    dispatch({ type: userActionTypes.START_GET_USER_INFO })

    const response = await userService.getUserInfo(data)
    dispatch({
      type: userActionTypes.GET_USER_INFO_SUCCESS,
      data: response.data.payload,
    })
  } catch (ex) {
    console.log('Get user info error: ' + ex.message)
    dispatch({
      type: userActionTypes.GET_USER_INFO_FAILURE,
      error: ex.response?.data,
    })
  }
}

const userActions = {
  login,
  register,
  logout,
  getUserInfo,
}

export default userActions
