import { useEffect, useState } from "react";
import categoryActions from "../../store/actions/product/category.actions";
import productActions from "../../store/actions/product/product.actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";

export const HomePageCategoriesListComponent = ({ categoryActions, categoryReducer }) => {
  const navigate = useNavigate();

  const [categoriesList, setCategoriesList] = useState([]);

  useEffect(() => {
    categoryActions.getCategories({ pageNumber: 1, pageSize: -1 });
  }, [categoryActions]);

  useEffect(() => {
    if (categoryReducer.categories?.items) {
      setCategoriesList(categoryReducer.categories.items);
    }
  }, [categoryReducer.categories?.items]);

  const handleCategoryClicked = (categoryRoute) => {
    navigate(`/collections/${categoryRoute}`);
  };

  return (
    <div className="homeCategoryListContainer container">
      {categoriesList.map((category) => (
        <div
          className="categoryCardContainer"
          key={category.id}
          onClick={() => handleCategoryClicked(category.categoryRoute)}
        >
          <div className="categoryImageContainer" style={{ backgroundImage: `url(${category.categoryImage})` }}>
            <div className="overlay">
              <label>{category.categoryName}</label>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  categoryReducer: state.categoryReducer,
});

const mapDispatchToProps = (dispatch) => ({
  categoryActions: bindActionCreators(categoryActions, dispatch),
  productActions: bindActionCreators(productActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePageCategoriesListComponent);


// import { useEffect, useState } from "react";
// import categoryActions from "../../store/actions/product/category.actions";
// import productActions from "../../store/actions/product/product.actions";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";

// import categoryImage from "../../assets/images/category-example.jpg";
// import { useNavigate } from "react-router-dom";

// export const HomePageCategoriesListComponent = ({ categoryActions, categoryReducer, productActions }) => {
//   const navigate = useNavigate();

//   const [categioriesList, setCategoriesList] = useState([]);

//   useEffect(() => {
//     categoryActions.getCategories({ pageNumber: 1, pageSize: -1 });
//   }, []);

//   useEffect(() => {
//     if (categoryReducer.categories?.items) {
//       setCategoriesList(categoryReducer.categories.items);
//     }
//   }, [categoryReducer.categories.items]);

//   const handleCategoryClicked = (categoryRoute) => {
//     navigate(`/collections/${categoryRoute}`);
//   };

//   return (
//     <div className="homeCategoryListContainer container">
//       {categioriesList.map((category) => (
//         <div
//           className="categoryCardContainer"
//           key={category.id}
//           onClick={() => {
//             handleCategoryClicked(category.categoryRoute);
//           }}
//         >
//           <div className="categoryImageContainer" style={{ backgroundImage: `url(${category.categoryImage})` }}>
//             <div className="overlay">
//               <label>{category.categoryName}</label>
//             </div>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// const mapStateToProps = (state, ownProps) => ({
//   userReducer: state.userReducer,
//   websiteReducer: state.websiteReducer,
//   categoryReducer: state.categoryReducer,
// });

// const mapDispatchToProps = (dispatch) => {
//   return {
//     categoryActions: bindActionCreators(categoryActions, dispatch),
//     productActions: bindActionCreators(productActions, dispatch),
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(HomePageCategoriesListComponent);
