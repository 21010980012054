import React from "react";

export const SelectInputElementComponent = ({ props }) => {
  const { title, label, name, value, options, onChange, required, clearBaseDesign, customStyleClass, disabled } = props;

  const getSelectElementOptions = () => {
    const elementOptions = [];
    elementOptions.push(
      <option key={-1} value="">
        בחר {label}
      </option>
    );
    options?.forEach((option) => {
      if (option.subOptions && option.subOptions.length > 0) {
        elementOptions.push(
          <optgroup key={option.value} label={option.label}>
            {option.subOptions?.map((subOption) => (
              <option key={subOption.value} value={subOption.value}>
                {subOption.label}
              </option>
            ))}
          </optgroup>
        );
      } else {
        elementOptions.push(
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        );
      }
    });

    return elementOptions;
  };

  return (
    <div className={`${clearBaseDesign ? "" : "select-element-container"} ${customStyleClass ? customStyleClass : ""}`}>
      <label htmlFor={name}>{title ? title : label}</label>
      <select id={name} name={name} value={value} onChange={onChange} required={required} disabled={disabled}>
        {getSelectElementOptions()}
      </select>
    </div>
  );
};

export default SelectInputElementComponent;
