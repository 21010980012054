import { userHttpClient } from '../../installations/axiosInterfaces'

export const login = (data) => {
  return userHttpClient.post(`/api/Account/Login`, data)
}

export const register = (data) => {
  return userHttpClient.post(`/api/Account/Register`, data)
}

export const getUserInfo = (data) => {
  return userHttpClient.get(`/api/Account/UserInfo/${data}`)
}

const userService = {
  login,
  register,
  getUserInfo,
}

export default userService
