const isStateLoading = (reducer) => {
  return reducer.stateInfo.isLoading;
};

const isStateSuccess = (reducer) => {
  return !reducer.stateInfo.isLoading && reducer.stateInfo.isSuccess;
};

const isStateFailuer = (reducer) => {
  return !reducer.stateInfo.isLoading && reducer.stateInfo.isError;
};


export const reducerStateHandler = {
    isStateLoading,
    isStateSuccess,
    isStateFailuer,
  };