import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextInputElementComponent from "../../../Generic/inputElements/TextInputElementComponent";
import SelectInputElementComponent from "../../../Generic/inputElements/SelectInputElementComponent";
import { genericInputElementsHandler } from "../../../../handlers/genericInputElements.handler";

export const AddOrUpdateBaseProductComponent = ({ props }) => {
  const { product, categories, isUpdateAction } = props;
  const { handleChange, handleSubmit } = props.functions;

  const getProductNameInputElement = () => {
    const productNameProps = {
      label: "שם פריט",
      name: "productName",
      value: product.productName,
      onChange: handleChange,
    };
    return <TextInputElementComponent props={productNameProps} />;
  };

  const getCategoryInputElement = () => {
    const categoryOptions = genericInputElementsHandler.transformDataToSelectObject(categories, "id", "categoryName", "subCategories");

    const props = {
      label: "קטגוריה",
      name: "categoryId",
      value: product.categoryId,
      options: categoryOptions,
      onChange: handleChange,
      required: true,
    };
    return <SelectInputElementComponent props={props} />;
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>פרטים בסיסיים</AccordionSummary>
      <AccordionDetails>
        <div>
          {getProductNameInputElement()}
          {getCategoryInputElement()}
          <button onClick={handleSubmit}>{isUpdateAction ? "עדכן" : "הוסף"}</button>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default AddOrUpdateBaseProductComponent;
