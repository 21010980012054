import { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import orderActions from "../../store/actions/order/order.actions";
import { connect } from "react-redux";

export const PaymentSuccessPage = ({ orderActions }) => {
  const [isAppLoading, setIsAppLoading] = useState(true);
  const [orderDetails, setOrderNumber] = useState();

  useEffect(() => {
    attachPaymentToOrder();
  }, []);

  const attachPaymentToOrder = async () => {
    try {
      setIsAppLoading(true);
      const queryParams = getQueryParams();
      const attachPaymentToOrderResponse = await orderActions.attachPaymentToOrder(queryParams);
      console.log("🚀 ~ attachPaymentToOrder ~ attachPaymentToOrderResponse:", attachPaymentToOrderResponse);

      if (attachPaymentToOrderResponse?.status === 200 && attachPaymentToOrderResponse?.data?.payload) {
        setOrderNumber(attachPaymentToOrderResponse?.data?.payload);
      }

      setIsAppLoading(false);
    } catch (error) {
      setIsAppLoading(false);
      console.error("Error attaching payment to order:", error);
    }
  };

  const getQueryParams = () => {
    const queryParams = new URLSearchParams(window.location.search);

    // Access individual query parameters
    const customerId = queryParams.get("OG-CustomerID");
    const paymentId = queryParams.get("OG-PaymentID");
    const paymentType = queryParams.get("OG-PaymentType");
    const externalIdentifier = queryParams.get("OG-ExternalIdentifier");
    const documentNumber = queryParams.get("OG-DocumentNumber");

    return { customerId, paymentId, paymentType, externalIdentifier, documentNumber };
  };

  if (isAppLoading) return <div>Loading...</div>;

  return (
    <div className="paymentSuccessPageContiner">
      <h1>תודה על ההזמנה!</h1>
      <span>אנחנו מודים לך שבחרת ב- ARIN Scented Candles</span>

      <div className="orderDetailsContainer">
        <h3>מספר הזמנה : {orderDetails.orderNumber}</h3>
        <span>פרטי ההזמנה נשלוח אלייך למייל.</span>
        <h6>פרטי המשלוח:</h6>
        <span>
          שם: {orderDetails.shippingAddress.personalDetails.firstName} {orderDetails.shippingAddress.personalDetails.lastName}
        </span>
        <span>
          כתובת: {orderDetails.shippingAddress.addressLine1} {orderDetails.shippingAddress.city}
        </span>
      </div>

      <div className="supportDetailsContiner">
        <p>אנחנו זמינים לכל שאלה או בקשה. ניתן לפנות אלינו בדרכי ההתקשרות:</p>
        <span>דואר אלקטרוני: support@arincandles.com</span>
        <span> WhatsApp: 050-2215233</span>
      </div>

      <div className="tanksContainer">
        <p>
          אנחנו מעריכים את בחירתך בנו ומאמינים שתהנה מהמוצרים שלנו. כמתנה קטנה מאיתנו, אנחנו שמחים להעניק לך קוד קופון לרכישה הבאה <span>THANKYOU10</span>
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    orderReducer: state.orderReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    orderActions: bindActionCreators(orderActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccessPage);
