import { useEffect, useState } from "react";
import GenericTableComponent from "../../../components/Generic/GenericTableComponent";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import categoryActions from "../../../store/actions/product/category.actions";
import { defaultCategory } from "../../../objects/defaults/defaultCategory";
import TextInputElementComponent from "../../../components/Generic/inputElements/TextInputElementComponent";
import TextAreaInputElementComponent from "../../../components/Generic/inputElements/TextAreaInputElementComponent";
import FileInputElementComponent from "../../../components/Generic/inputElements/FileInputElementComponent";
import { fileTypesEnum } from "../../../enums/fileTypesEnum";
import AlertService from "../../../services/general/alert.service";
import { reducerStateHandler } from "../../../handlers/reducerState.handler";
import { ActionTypesEnum } from "../../../enums/general/actinTypesEnum";
import { statusCodesEnum } from "../../../enums/general/statusCodesEnum";
import SelectInputElementComponent from "../../../components/Generic/inputElements/SelectInputElementComponent";

export const AddOrUpdateCategoryPage = ({ categoryActions, categoryReducer }) => {
  const navigate = useNavigate();
  const { categoryId, parentCategoryId, subcategoryId } = useParams();
  const [category, setCategory] = useState(defaultCategory);
  const [isUpdateAction, setIsUpdateAction] = useState(false);
  const [selectedCategoryImage, setSelectedCategoryImage] = useState();
  const [actionType, setActionType] = useState();
  const [isSubCategory, setIsSubCategory] = useState(false);

  // Use Effects functions
  useEffect(() => {
    if (categoryId) handleCategoryIdParamsChanged();
    else if (parentCategoryId && subcategoryId) {
      setIsSubCategory(true);
      handleSubCategoryIdParamsChanged();
    }
  }, [categoryId, parentCategoryId, subcategoryId]);

  useEffect(() => {
    handleStateInfoChanged();
  }, [categoryReducer.stateInfo]);
  // ****** ****** ****** ******

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCategory({ ...category, [name]: value });
  };

  const handleFileChange = (file, fileDetails) => {
    setSelectedCategoryImage(file);
    setCategory({ ...category, categoryImage: fileDetails.url });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const errorMessage = validateCategory();
    if (!errorMessage) {
      const formData = buildCategoryFormData();
      if (isUpdateAction) {
        setActionType(ActionTypesEnum.Update);
        categoryActions.updateCategory(isSubCategory ? subcategoryId : categoryId, formData);
      } else {
        setActionType(ActionTypesEnum.Add);
        categoryActions.addCategory(formData);
      }
    } else {
      alert(errorMessage);
    }
  };

  const validateCategory = () => {
    let errorMessage;

    if (!category.categoryName) errorMessage = "יש להזין שם קטגוריה";
    else if (!category.description) errorMessage = "יש להזין פירוט לקטגוריה";

    return errorMessage;
  };

  const handleCategoryIdParamsChanged = () => {
    const selectedCategory = categoryReducer?.categories?.items?.find((c) => c.id === categoryId);
    if (selectedCategory) {
      setCategory({ ...selectedCategory });
      setIsUpdateAction(true);
    } else {
      AlertService.error("קטגוריה לא נמצאה", "אנה נסה שנית מאוחר יותר");
      navigate(-1);
    }
  };

  const handleSubCategoryIdParamsChanged = () => {
    const parentCategory = categoryReducer?.categories?.items?.find((category) => category.id === parentCategoryId);
    const selectedCategory = parentCategory?.subCategories?.find((category) => category.id === subcategoryId);

    if (selectedCategory) {
      setCategory({ ...selectedCategory });
      setIsUpdateAction(true);
    } else {
      AlertService.error("קטגוריה לא נמצאה", "אנה נסה שנית מאוחר יותר");
      navigate(-1);
    }
  };

  const handleStateInfoChanged = () => {
    if (actionType === ActionTypesEnum.Add || actionType === ActionTypesEnum.Update) {
      if (reducerStateHandler.isStateSuccess(categoryReducer)) {
        const message = isUpdateAction ? "קטגוריה עודכנה בהצלחה" : "קטגוריה נוספה בהצלחה";
        AlertService.success(message);
        setActionType(null);
        navigate(-1);
      } else if (reducerStateHandler.isStateFailuer(categoryReducer)) {
        const { errorStatusCode } = categoryReducer.stateInfo;
        const messageObject = getFailuerMessage(errorStatusCode);
        AlertService.error(messageObject.title, messageObject.description);
        setActionType(null);
      }
    }
  };

  // Helper function
  const getFailuerMessage = (statusCode) => {
    switch (statusCode) {
      case statusCodesEnum.CONFLICT:
        return { title: "קטגוריה עם שם זהה קיימת במערכת" };
      default:
        return {
          title: isUpdateAction ? "שגיאה בעדכון קטגוריה" : "שגיאה בהוספת קטגוריה",
        };
    }
  };

  const buildCategoryFormData = () => {
    const formData = new FormData();
    formData.append("CategoryName", category.categoryName);
    formData.append("Description", category.description);
    formData.append("CategoryRoute", category.categoryRoute);

    if (category.parentCategoryId) {
      formData.append("ParentCategoryId", category.parentCategoryId);
    }

    if (selectedCategoryImage) {
      formData.append("MainImage", selectedCategoryImage);
    }

    return formData;
  };
  // ****** ****** ****** ******

  // Get Form Elements
  const getCategoryNameInputElement = () => {
    const props = {
      label: "שם קטגוריה",
      name: "categoryName",
      value: category.categoryName,
      onChange: handleInputChange,
    };
    return <TextInputElementComponent props={props} />;
  };

  const getCategoryDescriptionInputElement = () => {
    const props = {
      label: "תיאור",
      name: "description",
      value: category.description,
      onChange: handleInputChange,
    };
    return <TextAreaInputElementComponent props={props} />;
  };

  const getParentCategoryInputElement = () => {
    const categoryOptions = categoryReducer.categories?.items?.map((item) => {
      return { value: item.id, label: item.categoryName };
    });
    const props = {
      label: "קטגוריית אב",
      name: "parentCategoryId",
      value: category.parentCategoryId,
      options: categoryOptions,
      onChange: handleInputChange,
    };
    return <SelectInputElementComponent props={props} />;
  };

  const getCategoryRouteInputElement = () => {
    const props = {
      label: "ניתוב באתר",
      name: "categoryRoute",
      value: category.categoryRoute,
      onChange: handleInputChange,
    };
    return <TextInputElementComponent props={props} />;
  };

  const getCategoryImageInputElement = () => {
    const props = {
      label: "תמונת קטגוריה",
      name: "categoryImage",
      value: category.categoryImage,
      fileType: fileTypesEnum.image,
      onChange: handleFileChange,
    };
    return <FileInputElementComponent props={props} />;
  };
  // ****** ****** ****** ******

  return (
    <div className="add-or-update-product-container">
      <h2>{isUpdateAction ? "עריכת קטגוריה" : "הוספת קטגוריה"}</h2>
      <form onSubmit={handleSubmit}>
        {getCategoryNameInputElement()}
        {getCategoryDescriptionInputElement()}
        {getParentCategoryInputElement()}
        {getCategoryRouteInputElement()}
        {getCategoryImageInputElement()}

        <button type="submit" className="btn-submit">
          {isUpdateAction ? "עדכן" : "הוסף"}
        </button>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    categoryReducer: state.categoryReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    categoryActions: bindActionCreators(categoryActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOrUpdateCategoryPage);
