import orderService from "../../../services/order/order.service";
import paymentService from "../../../services/order/payment.service";
import { orderActionTypes } from "../../actionTypes/order/orderActionTypes";

export const addOrder = (data) => async (dispatch) => {
  let response;
  try {
    dispatch({ type: orderActionTypes.START_ADD_ORDER });

    response = await orderService.addOrder(data);
    dispatch({ type: orderActionTypes.ADD_ORDER_SUCCESS });
  } catch (ex) {
    console.log("addOrder error: " + ex.message);
    dispatch({ type: orderActionTypes.ADD_ORDER_FAILURE, data: ex.response?.status });
  }

  return response;
};

// ************** Payment **************
export const attachPaymentToOrder = (data) => async (dispatch) => {
  let response;
  try {
    dispatch({ type: orderActionTypes.START_ATTACH_PAYMENT_TO_ORDER });

    response = await paymentService.attachPaymentToOrder(data);
    dispatch({ type: orderActionTypes.ATTACH_PAYMENT_TO_ORDER_SUCCESS });
  } catch (ex) {
    console.log("attachPaymentToOrder error: " + ex.message);
    dispatch({ type: orderActionTypes.ATTACH_PAYMENT_TO_ORDER_FAILURE, data: ex.response?.status });
  }

  return response;
};

const orderActions = {
  addOrder,
  attachPaymentToOrder,
};

export default orderActions;
