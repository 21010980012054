import React from "react";
import { Route, Routes } from "react-router-dom";
import ProductListPage from "../pages/products/ProductsListPage";
import DashboardPage from "../pages/dashboard/DashboardPage";
import HomePage from "../pages/HomePage";
import DashboardCategoryListPage from "../pages/dashboard/category/DashboardCategoryListPage";
import AddOrUpdateCategoryPage from "../pages/dashboard/category/AddOrUpdateCategoryPage";
import DashboardProductsListPage from "../pages/dashboard/product/DashboardProductsListPage";
import AddUpdateProductPage from "../pages/dashboard/product/AddUpdateProductPage";
import ProductsListPage from "../pages/products/ProductsListPage";
import ProductDetailsPage from "../pages/products/ProductDetailsPage";
import CheckoutPage from "../pages/CheckoutPage";
import PaymentSuccessPage from "../pages/payment/PaymentSuccessPage";
import ShoppingCartPage from "../pages/ShoppingCartPage";
import LoginRegisterPage from "../pages/LoginRegisterPage";
import DashboardProductPropertiesListPage from "../pages/dashboard/product/properties/DashboardProductPropertiesListPage";
import AddUpdateProductPropertyPage from "../pages/dashboard/product/properties/AddUpdateProductPropertyPage";

// Pages

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/products" element={<ProductListPage />} />
      <Route path="/dashboard" element={<DashboardPage />}>
        <Route path="overview" element={<HomePage />} />
        {/* Dashboard Categories Routes  */}
        <Route path="categories" element={<DashboardCategoryListPage />} />
        <Route path="categories/add" element={<AddOrUpdateCategoryPage />} />
        <Route path="categories/edit/:parentCategoryId/sub/:subcategoryId" element={<AddOrUpdateCategoryPage />} />
        <Route path="categories/edit/:categoryId" element={<AddOrUpdateCategoryPage />} />
        {/* Dashboard  Products Routes */}
        <Route path="products" element={<DashboardProductsListPage />} />
        <Route path="products/add" element={<AddUpdateProductPage />} />
        <Route path="products/edit/:productId" element={<AddUpdateProductPage />} />
        <Route path="products/properties" element={<DashboardProductPropertiesListPage />} />
        <Route path="products/properties/add" element={<AddUpdateProductPropertyPage />} />
        <Route path="products/properties/edit:/propertyId" element={<AddUpdateProductPropertyPage />} />
      </Route>
      <Route path="collections/:categoryRoute" element={<ProductsListPage />} />
      <Route path="collections/:parentCategoryId/:childCategoryRoute" element={<ProductsListPage />} />
      <Route path="Products/:productId/:productName" element={<ProductDetailsPage />} />

      <Route path="/checkout" element={<CheckoutPage />} />
      <Route path="/checkout/paymentSuccess" element={<PaymentSuccessPage />} />
      <Route path="/checkout/paymentFailed" element={<CheckoutPage />} />

      <Route path="/shoppingCart" element={<ShoppingCartPage />} />
      <Route path="/register" element={<LoginRegisterPage />} />
      <Route path="/login" element={<LoginRegisterPage />} />
      <Route path="/" element={<HomePage />} />
    </Routes>
  );
};

export default AppRoutes;
