import axios from "axios";

const productHttpClient = axios.create({
  baseURL: process.env.REACT_APP_API_GATEWAY_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const userHttpClient = axios.create({
  baseURL: process.env.REACT_APP_API_GATEWAY_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const filesHttpClient = axios.create({
  baseURL: process.env.REACT_APP_API_GATEWAY_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const orderHttpClient = axios.create({
  baseURL: process.env.REACT_APP_API_GATEWAY_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export { productHttpClient, userHttpClient, filesHttpClient, orderHttpClient };
