import { defaultProductVarient } from "./product/defaultProductVarient";

export const defaultProduct = {
  id: null,
  productName: "",
  categoryId: "",
  description: {
    id: null,
    descriptionText: "",
    subDescriptionText: "",
  },
  variants: [defaultProductVarient],
  images: [
    {
      id: null,
      productId: "",
      imageURL: "",
      isPrimary: false,
    },
  ],
  additionalAttributes: [
    {
      id: null,
      productId: "",
      attributeName: "",
      attributeValue: "",
    },
  ],
  faqs: [
    {
      id: null,
      productId: "",
      question: "",
      answer: "",
    },
  ],
  reviews: [
    {
      id: null,
      productId: "",
      userId: "",
      rating: 0,
      reviewText: "",
      reviewDate: null,
    },
  ],
};

// export const defaultProduct = {
//   id: "",
//   productCode: "",
//   productName: "",
//   description: "",
//   price: 0,
//   stockQuantity: 0,
//   categoryId: "",
//   productImage: "",
//   // productProperties: []
// };
