import categoryService from "../../../services/product/category.service";
import { categoryActionTypes } from "../../actionTypes/product/categoriesActionTypes";

export const getCategories = (data) => async (dispatch) => {
  try {
    dispatch({ type: categoryActionTypes.START_GET_CATEGORIES });

    const response = await categoryService.getCategories(data);
    dispatch({
      type: categoryActionTypes.GET_CATEGORIES_SUCCESS,
      data: response.data?.payload,
    });
  } catch (ex) {
    console.log("getCategories error: " + ex.message);
    dispatch({ type: categoryActionTypes.GET_CATEGORIES_FAILURE, data: ex.response?.status });
  }
};

export const getCategoryById = (id) => async (dispatch) => {
  try {
    dispatch({ type: categoryActionTypes.START_GET_CATEGORY });

    const response = await categoryService.getCategoryById(id);
    dispatch({
      type: categoryActionTypes.GET_CATEGORY_SUCCESS,
      data: response.data?.payload,
    });
  } catch (ex) {
    console.log("getCategoryById error: " + ex.message);
    dispatch({ type: categoryActionTypes.GET_CATEGORY_FAILURE, data: ex.response?.status });
  }
};

export const addCategory = (category) => async (dispatch) => {
  try {
    dispatch({ type: categoryActionTypes.START_ADD_CATEGORY });

    const response = await categoryService.addCategory(category);
    dispatch({
      type: categoryActionTypes.ADD_CATEGORY_SUCCESS,
      data: response.data,
    });
  } catch (ex) {
    console.log("addCategory error: " + ex.message);
    console.log(ex);
    dispatch({ type: categoryActionTypes.ADD_CATEGORY_FAILURE, data: ex.response?.status });
  }
};

export const updateCategory = (id, category) => async (dispatch) => {
  try {
    dispatch({ type: categoryActionTypes.START_UPDATE_CATEGORY });

    const response = await categoryService.updateCategory(id, category);
    dispatch({
      type: categoryActionTypes.UPDATE_CATEGORY_SUCCESS,
      data: response.data,
    });
  } catch (ex) {
    console.log("updateCategory error: " + ex.message);
    dispatch({ type: categoryActionTypes.UPDATE_CATEGORY_FAILURE, data: ex.response?.status });
  }
};

export const deleteCategory = (id) => async (dispatch) => {
  try {
    dispatch({ type: categoryActionTypes.START_DELETE_CATEGORY });

    const response = await categoryService.deleteCategory(id);
    dispatch({
      type: categoryActionTypes.DELETE_CATEGORY_SUCCESS,
      data: id,
    });
  } catch (ex) {
    console.log("deleteCategory error: " + ex.message);
    dispatch({ type: categoryActionTypes.DELETE_CATEGORY_FAILURE, data: ex.response?.status });
  }
};

const categoryActions = {
  getCategories,
  getCategoryById,
  addCategory,
  updateCategory,
  deleteCategory,
};

export default categoryActions;
