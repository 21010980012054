import { productHttpClient } from '../../installations/axiosInterfaces'

const getPropertyDefinitions = () => {
  return productHttpClient.get(`/api/Property/definitions`)
}

const propertyService = {
  getPropertyDefinitions,
}

export default propertyService
