import { useNavigate } from "react-router-dom";
import noImage from "../../assets/images/default/noimage1.png";
import { productHandler } from "../../handlers/product.handler";

const ProductCardComponent = ({ product }) => {
  const navigate = useNavigate();

  const handleProductCardClicked = (productId, productName) => {
    navigate(`/Products/${productId}/${productName}`);
  };

  const getProductPrimaryImageUrl = () => {
    if (product.images && product.images.length > 0) {
      const primaryImageDetails = product.images.find((img) => img.isPrimary);
      return primaryImageDetails ? primaryImageDetails.imageURL : product.images[0].imageURL || noImage;
    }
    return noImage;
  };

  return (
    <div
      key={product.id}
      className="productCardContainer"
      onClick={() => {
        handleProductCardClicked(product.id, product.productName);
      }}
    >
      <div className="productImageContainer">
        <img src={getProductPrimaryImageUrl()} alt={product.productName} />
      </div>
      <div className="cardContentContainer">
        <span className="productName">{product.productName}</span>
        <span>החל מ- {productHandler.getMinProductVarientPrice(product)} ₪</span>
      </div>
    </div>
  );
};

export default ProductCardComponent;
