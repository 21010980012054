import { useNavigate } from "react-router-dom";

export const DashboardProductPropertiesListPage = () => {
  const navigate = useNavigate();
  return (
    <div>
      <button onClick={() => navigate(`add`)}>הוספת מאפיין מוצר</button>
    </div>
  );
};

export default DashboardProductPropertiesListPage;
