import ProductCardComponent from "./ProductCard";

const ProductCardListComponent = ({ products }) => {
  return (
    <div className="productCardsListContainer">
      {products?.map((product) => (
        <ProductCardComponent key={product.id} product={product} />
      ))}
    </div>
  );
};
export default ProductCardListComponent;
