import { useEffect, useState } from "react";
import CheckoutAddressDetailsComponent from "./CheckoutAddressDetailsComponent";

export const CheckoutOrderDetailsComponent = ({ props }) => {
  const { orderDetails, deliveryTypesOptions, paymentAddressTypesOptions, selectedPaymentAddressOption } = props;
  const { handleChangeOrderDetails, handleDeliveryAddressChange, handlePaymentAddressChange } = props.functions;

  const [showPaymentAddressFormElement, setShowPaymentAddressFormElement] = useState(false);

  useEffect(() => {
    setShowPaymentAddressFormElement(selectedPaymentAddressOption === 2 ? true : false);
  }, [selectedPaymentAddressOption]);

  const getDeliveryAddressElement = () => {
    const inputProps = { headerTitle: "משלוח", orderDetails, address: { ...orderDetails.shippingAddress } };
    inputProps.functions = { handleChange: handleDeliveryAddressChange };
    return <CheckoutAddressDetailsComponent props={inputProps} />;
  };

  const getDeliveryTypeElement = () => {
    return (
      <div className="checkoutRadioGroupContainer">
        <span>שיטת משלוח</span>
        <div className="checkoutRadioGroupOptionsListContainer">
          {deliveryTypesOptions.map((option) => (
            <div key={option.value} className="checkoutRadioOptionContainer">
              <input type="radio" id={`deliveryType${option.value}`} name="deliveryType" value={option.value} checked={orderDetails.deliveryType === option.value} onChange={handleChangeOrderDetails} />
              <label htmlFor={`deliveryType${option.value}`}>{option.descripton}</label>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const getPaymentExplanationElement = () => {
    return (
      <div className="paymentExplanationContainer">
        <div>
          <span>תשלום</span>
        </div>
        <p>
          ניתן לשלם באמצעות כרטיס אשראי. כל העסקאות מאובטחות ומצפנות ופרטייך האישיים לא נשמרים אצלנו במערכת. <br />
          לאחר לחיצה על כפתור "תשלום", תועבר לעמוד תשלום מאובטח
        </p>
      </div>
    );
  };

  const getPaymentAddressTypeseElement = () => {
    return (
      <div className="checkoutRadioGroupContainer">
        <span>פרטים עבור הפקת החשבונית</span>
        <div className="checkoutRadioGroupOptionsListContainer">
          {paymentAddressTypesOptions.map((option) => (
            <div className="checkoutRadioOptionContainer" key={option.value}>
              <input type="radio" id={`paymentAddressType${option.value}`} name="paymentAddressType" value={option.value} checked={selectedPaymentAddressOption === option.value} onChange={handleChangeOrderDetails} />
              <label htmlFor={`paymentAddressType${option.value}`}>{option.descripton}</label>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const getPaymentAddressElement = () => {
    const inputProps = { headerTitle: "פרטים לחיוב", orderDetails, address: { ...orderDetails.billingAddress } };
    inputProps.functions = { handleChange: handlePaymentAddressChange };
    return <CheckoutAddressDetailsComponent props={inputProps} />;
  };

  return (
    <div className="checkoutOrderDetailsContainer">
      {getDeliveryAddressElement()}
      {getDeliveryTypeElement()}
      {getPaymentExplanationElement()}
      {getPaymentAddressTypeseElement()}
      {showPaymentAddressFormElement && orderDetails.billingAddress && getPaymentAddressElement()}
    </div>
  );
};

export default CheckoutOrderDetailsComponent;
