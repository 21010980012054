export const TextInputElementComponent = ({ props }) => {
  const { name, label, value, onChange, required, clearDesgin, customDesignClass, placeholder } = props;
  return (
    <div className={`${clearDesgin ? "" : "text-element-container"} ${customDesignClass}`}>
      <label>{label}</label>
      <input name={name} value={value} onChange={onChange} required={required} placeholder={placeholder} />
    </div>
  );
};

export default TextInputElementComponent;
