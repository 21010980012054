import React, { useEffect, useState } from 'react'
import logo from '../assets/images/blackLogo.png'
import whiteLogo from '../assets/images/ArinLightLogo.png'
import { Link, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import categoryActions from '../store/actions/product/category.actions'
import userActions from '../store/actions/user/user.actions'
import ShoppingCartIconComponent from './icons/ShoppingCartIconComponent'

const HeaderComponent = ({
  props,
  categoryActions,
  categoryReducer,
  userActions,
}) => {
  const {
    isAuthenticated,
    isMobileScreen,
    isMenuOpen,
    loggedInUserInfo,
    isAdministrator,
  } = props
  const { toggleOpenNavMenu, logout } = props.functions

  const navigate = useNavigate()

  const [isOpen, setIsOpen] = useState(false)
  const [categories, setCategories] = useState([])
  const [hoveredCategoryId, setHoveredCategoryId] = useState()

  useEffect(() => {
    categoryActions.getCategories({ pageNumber: 1, pageSize: -1 })
  }, [])

  useEffect(() => {
    if (categoryReducer?.categories)
      setCategories(categoryReducer.categories.items)
  }, [categoryReducer?.categories])

  const handleHamburgerMenuClicked = () => {
    toggleOpenNavMenu(!isMenuOpen)
  }

  const handleNavLinkClicked = (category) => {
    if (isMobileScreen && !IsCategoryHasChildes(category)) {
      toggleOpenNavMenu()
      setHoveredCategoryId(null)
    } else if (isMobileScreen && IsCategoryHasChildes(category)) {
      if (hoveredCategoryId == category.id) setHoveredCategoryId(null)
      else setHoveredCategoryId(category.id)
    }
    // if (isMobileScreen) toggleOpenNavMenu()
  }

  const handleLogoClicked = () => {
    navigate('/')
  }

  const handleMouseEnter = (categoryId) => {
    if (!isMobileScreen) setHoveredCategoryId(categoryId)
  }

  const handleMouseLeave = () => {
    if (!isMobileScreen) setHoveredCategoryId(null)
  }

  const handleSubCategoryClicked = (parentCategoryId, subCategoryRoute) => {
    navigate(`/collections/${parentCategoryId}/${subCategoryRoute}`)
    handleMouseLeave()
  }

  // Helper Functions

  const IsCategoryHasChildes = (category) => {
    return category.subCategories && category.subCategories.length > 0
  }

  // Get Elements Functions
  const getHamburgerMenuElement = () => {
    const element = (
      <div className="hamburgerMenu" onClick={handleHamburgerMenuClicked}>
        <i className="fa-solid fa-bars"></i>
      </div>
    )
    return isMobileScreen ? element : null
  }

  const getContentContainerElement = () => {
    const element = (
      <div className="contentContainer">
        {isMenuOpen && (
          <div className="navlogoContainer">
            <img src={whiteLogo} alt="The Flamme Logo" />
          </div>
        )}

        <div className="NavContainer">
          {getMappedLinksElement()}

          {isAuthenticated && isAdministrator && (
            <Link
              key="dashboard"
              to={`/dashboard/`}
              onClick={handleNavLinkClicked}
            >
              איזור אישי
            </Link>
          )}
        </div>

        {/* <div className="actionIconsContainer">
          {!isAuthenticated && getMemmberLoginElement()}
          {isAuthenticated && getMemmberLoggedInElement()}
          {getSearchElement()}
          {getShoppingCartElement()}
        </div> */}
      </div>
    )

    return (isMobileScreen && isMenuOpen) || !isMobileScreen ? element : null
  }

  const getMappedLinksElement = () => {
    const element = categories?.map((category) => (
      <div
        key={category.id}
        className="categoryCotainer"
        onMouseEnter={() => handleMouseEnter(category.id)}
      >
        {!IsCategoryHasChildes(category) && (
          <Link
            to={`/collections/${category.categoryRoute}`}
            onClick={() => {
              handleNavLinkClicked(category)
            }}
          >
            {category.categoryName}
          </Link>
        )}
        {!isMobileScreen && IsCategoryHasChildes(category) && (
          <span>{category.categoryName}</span>
        )}

        {isMobileScreen && IsCategoryHasChildes(category) && (
          <div
            className="parentCategoryConentContainer"
            onClick={() => {
              handleNavLinkClicked(category)
            }}
          >
            <span>{category.categoryName} </span>
            {hoveredCategoryId === category.id ? (
              <i className="fa-solid fa-arrow-up"></i>
            ) : (
              <i className="fa-solid fa-arrow-down"></i>
            )}
          </div>
        )}
        {isMobileScreen &&
          hoveredCategoryId === category.id &&
          category.subCategories.map((subCategory) => (
            <Link
              key={subCategory.id}
              to={`/collections/${category.id}/${subCategory.categoryRoute}`}
              onClick={handleNavLinkClicked}
              className="categoryLink subCategoryLink"
            >
              {subCategory.categoryName}
            </Link>
          ))}

        {!isMobileScreen &&
          hoveredCategoryId === category.id &&
          IsCategoryHasChildes(category) && (
            <div className="subCategoriesListContainer container">
              {category.subCategories.map((subCategory) =>
                getSubCategoryCardElement(category.id, subCategory),
              )}
            </div>
          )}
      </div>
    ))

    return element
  }

  const getSubCategoryCardElement = (parentCategoryId, subCategory) => {
    const element = (
      <div
        key={subCategory.id}
        className="subCategoryCardContainer"
        onClick={() => {
          handleSubCategoryClicked(parentCategoryId, subCategory.categoryRoute)
        }}
      >
        <div className="imageContainer">
          <img src={subCategory.categoryImage} alt={subCategory.categoryName} />
        </div>
        <span>
          {subCategory.categoryName} <i className="fa-solid fa-arrow-left"></i>
        </span>
      </div>
    )

    return element
  }

  const getMemmberLoginElement = () => {
    return (
      <a href="/login">
        <i className="fa-solid fa-user"></i>
      </a>
    )
  }

  const getSearchElement = () => {
    return (
      <a href="/shoppingCart">
        <i className="fa-solid fa-magnifying-glass"></i>
      </a>
    )
  }

  const getShoppingCartElement = () => {
    return <ShoppingCartIconComponent />
  }

  const getMemmberLoggedInElement = () => {
    return (
      // <div className="loggedInMemberContainer" onMouseEnter={handleMouseLeave}>
      //   {loggedInUserInfo && !isMobileScreen  && <span>שלום {loggedInUserInfo.firstName}</span>}
      //   <div className="logoutContainer" onClick={logout}>
      //     <i className="fa-solid fa-right-from-bracket"></i>
      //   </div>
      // </div>

      <a href="/" onClick={logout}>
        <i className="fa-solid fa-right-from-bracket"></i>
      </a>
    )
  }

  const getMainLogoContainer = () => {
    return (
      <div
        className="logoContainer"
        onClick={handleLogoClicked}
        onMouseEnter={handleMouseLeave}
      >
        <img src={logo} alt="The Scent Logo" />
      </div>
    )
  }

  return (
    <header>
      <div
        className="container headerContainer"
        onMouseLeave={handleMouseLeave}
      >
        {getHamburgerMenuElement()}
        {getMainLogoContainer()}
        {getContentContainerElement()}

        <div className="actionIconsContainer">
          {!isAuthenticated && getMemmberLoginElement()}
          {isAuthenticated && getMemmberLoggedInElement()}
          {getSearchElement()}
          {getShoppingCartElement()}
        </div>
      </div>
    </header>
  )
}

const mapStateToProps = (state) => {
  return {
    categoryReducer: state.categoryReducer,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    categoryActions: bindActionCreators(categoryActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent)
// export default HeaderComponent
