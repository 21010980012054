import { connect } from "react-redux";
import categoryActions from "../../store/actions/product/category.actions";
import productActions from "../../store/actions/product/product.actions";
import { bindActionCreators } from "redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ActionTypesEnum } from "../../enums/general/actinTypesEnum";
import AlertService from "../../services/general/alert.service";
import SelectInputElementComponent from "../../components/Generic/inputElements/SelectInputElementComponent";
import ProductDetailsThumbGalleryComponent from "../../components/products/ProductDetailsThumbGalleryComponent";
import { defaultCartItem } from "../../objects/shoppingCart/defaultCartItem";
import shoppingCartService from "../../services/local/shoppingCart.service";
import ShoppingCartQuantityInputComponent from "../../components/shoppingCart/ShoppingCartQuantityInputComponent";
import { productHandler } from "../../handlers/product.handler";
import { textHelper } from "../../helpers/text.helper";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getProductPrimaryImageUrl } from "../../helpers/products/productHelpers";
const defaultSizes = [
  { id: 1, desc: "קטן 200 גרם" },
  { id: 2, desc: "בינוני 400 גרם" },
  { id: 3, desc: "גדול 500 גרם" },
];

export const ProductDetailsPage = ({ productActions, productReducer }) => {
  const { productId } = useParams();
  const [product, setProduct] = useState();
  const [actionType, setActionType] = useState();
  const [isAppLoading, setIsAppLoading] = useState(false);

  const [cartItem, setCartItem] = useState({ ...defaultCartItem });
  const [isItemAlreadyExistsOnCart, setIsItemAlreadyExistsOnCart] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState(null);

  useEffect(() => {
    productActions.getProductAttributes();
  }, [product]);

  useEffect(() => {
    if (selectedVariant) buildCartItem();
  }, [selectedVariant]);

  useEffect(() => {
    getProduct();
  }, [productId]);

  useEffect(() => {
    if (actionType === ActionTypesEnum.get && productReducer.product) {
      setProduct(productReducer.product);
      setIsAppLoading(false);
      setActionType(null);
    }
  }, [productReducer.product]);

  const getProduct = () => {
    if (!productReducer.products.items) {
      productActions.getProductById(productId);
      setActionType(ActionTypesEnum.get);
    } else {
      const currentProduct = productReducer.products.items.find((product) => product.id === productId);
      if (currentProduct) {
        setProduct(currentProduct);
      } else {
        AlertService.error("מוצר לא נמצא", "אנא נסה שנית מאוחר יותר");
      }
    }
  };

  const buildCartItem = () => {
    if (selectedVariant) {
      const storedVarientCartItem = shoppingCartService.getCartItemByIdAndVarientId(product.id, selectedVariant.id);

      if (storedVarientCartItem) {
        setIsItemAlreadyExistsOnCart(true);
        setCartItem({ ...storedVarientCartItem });
      } else {
        const cartItemDetails = { ...defaultCartItem, productId: product.id, productName: product.productName, varientId: selectedVariant.id, varientName: selectedVariant.name, price: selectedVariant.price, sku: selectedVariant.sku, quantity: 1, imageUrl: getProductPrimaryImageUrl(product) };
        setIsItemAlreadyExistsOnCart(false);
        setCartItem(cartItemDetails);
      }
    }
  };

  // Handle Change Functions

  const handleVariantChange = (event) => {
    const { value } = event.target;
    const variant = product.variants.find((v) => v.id === value);
    setSelectedVariant(variant);
  };

  const addItemCartQuantity = () => {
    const newQuantity = cartItem.quantity + 1;

    if (newQuantity > product.stockQuantity) AlertService.info(`כמות מקסימלית להזמנה מפריט זה ${product.stockQuantity}`);
    else setCartItem({ ...cartItem, quantity: newQuantity });
  };

  const reduceItemCartQuantity = () => {
    const newQuantity = cartItem.quantity - 1;
    if (newQuantity > 0) setCartItem({ ...cartItem, quantity: newQuantity });
  };

  const handleAddOrUpdateShoppingCartItemCliecked = () => {
    if (isItemAlreadyExistsOnCart) {
      shoppingCartService.updateItemQuantityByVarient(cartItem.productId, cartItem.varientId, cartItem.quantity);
    } else {
      shoppingCartService.addItem(cartItem);
      setIsItemAlreadyExistsOnCart(true);
    }
  };

  const handleRemoveFromCartClicked = () => {
    shoppingCartService.removeItemByVarientId(cartItem.productId, cartItem.varientId);
    buildCartItem();
    setIsItemAlreadyExistsOnCart(false);
  };

  const getVariantSelectElement = () => {
    const variantOptions = product.variants.map((variant) => {
      return { value: variant.id, label: variant.name };
    });

    const props = {
      title: "אפשרויות",
      label: "אפשרות",
      name: "variant",
      options: variantOptions,
      onChange: handleVariantChange,
    };

    return (
      <div className="selectVariantContainer">
        <SelectInputElementComponent props={props} />
      </div>
    );
  };

  const getVariantAttributesElements = () => {
    const groupedAttributes = productHandler.groupVariantAttributes(product, productReducer.productAttributes ? productReducer.productAttributes : []);
    return (
      <div className="varientAttributesContainer">
        {groupedAttributes.map((attributeGroup, idx) => (
          <div key={idx}>
            <span>{attributeGroup.attributeName}</span> : <span>{textHelper.formatTextList(attributeGroup.values)}</span>
          </div>
        ))}
      </div>
    );
  };

  const getShoppingCartQuantityElement = () => {
    const shoppingCartQuantityProps = {
      cartItem,
      handleAdd: addItemCartQuantity,
      handleReduce: reduceItemCartQuantity,
    };

    return <ShoppingCartQuantityInputComponent props={shoppingCartQuantityProps} />;
  };

  const getQuesAnsElement = () => {
    return (
      <div className="qusAnsListContainer">
        {product.faQs?.map((questionAnswer) => (
          <Accordion key={questionAnswer.id}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div>{questionAnswer.question}</div>
            </AccordionSummary>
            <AccordionDetails>
              <div>{questionAnswer.answer}</div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    );
  };

  const getProductPriceElement = () => {
    if (!selectedVariant) {
      const minPrice = productHandler.getMinProductVarientPrice(product);
      const maxPrice = productHandler.getMaxProductVarientPrice(product);
      if (minPrice === maxPrice) return <span className="priceContent">{maxPrice} ₪</span>;
      else return <span className="priceContent">החל מ- {productHandler.getMinProductVarientPrice(product)} ₪</span>;
    } else return <span className="priceContent">{selectedVariant.price} ₪</span>;
  };

  const getAddToCartButtonElement = () => {
    return (
      <button className="buttonDesign" onClick={handleAddOrUpdateShoppingCartItemCliecked}>
        {isItemAlreadyExistsOnCart ? "עדכון סל" : "הוספה לסל"}
      </button>
    );
  };

  const getRemoveFromCartButtonElement = () => {
    return (
      isItemAlreadyExistsOnCart && (
        <button className="buttonDesign" onClick={handleRemoveFromCartClicked}>
          הסר
        </button>
      )
    );
  };

  if (!product) {
    return <div>מוצר לא נמצא..</div>;
  }

  return (
    <div className="productDetailsPage container">
      <div className="productDetailsPageContainer ">
        <ProductDetailsThumbGalleryComponent product={product} />

        <div className="productDetailsContainer">
          <h1>{product.productName}</h1>
          {getProductPriceElement()}
          <span className="descriptionContent">{product.description?.descriptionText}</span>

          {getVariantAttributesElements()}
          {getVariantSelectElement()}
          {selectedVariant && getShoppingCartQuantityElement()}

          {selectedVariant && getAddToCartButtonElement()}
          {selectedVariant && getRemoveFromCartButtonElement()}
        </div>
      </div>
      <div className="productInfoContainer">
        <h3>פרטים נוספים</h3>
        <p>{product.description?.subDescriptionText}</p>
        <h3>שאלות נפוצות</h3>
        {getQuesAnsElement()}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  productReducer: state.productReducer,
  categoryReducer: state.categoryReducer,
});

const mapDispatchToProps = (dispatch) => ({
  categoryActions: bindActionCreators(categoryActions, dispatch),
  productActions: bindActionCreators(productActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailsPage);
