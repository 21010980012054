import { useState } from "react";

const FilterFromSelectListDialogComponent = ({ props }) => {
  const { attribute, isOpen, attributeValuesList } = props;
  const { applyAttributeFilter, onClose } = props.functions;
  const [selectedAttributeList, setSelectedAttributeList] = useState([]);

  const handleCheckboxChange = (option) => {
    setSelectedAttributeList((prevList) => (prevList.includes(option) ? prevList.filter((item) => item !== option) : [...prevList, option]));
  };

  const handleFilterClick = () => {
    applyAttributeFilter(attribute.id, selectedAttributeList);
    onClose(attribute.id);
  };

  return (
    isOpen && (
      <div className="fragranceFilterDialogContainer">
        <div className="dialogHeaderContainer">
          <span>{selectedAttributeList.length} נבחרו</span>
          <button>איפוס</button>
        </div>
        <div className="fragranceOptionsContainer">
          {attributeValuesList.map((option, idx) => (
            <label key={idx}>
              <input type="checkbox" checked={selectedAttributeList.includes(option.id)} onChange={() => handleCheckboxChange(option.id)} />
              {option.value}
            </label>
          ))}
        </div>
        <div className="actionContainer" onClick={handleFilterClick}>
          <button>סנן</button>
        </div>
      </div>
    )
  );
};

export default FilterFromSelectListDialogComponent;

// import { useState } from "react";

// const FilterFromSelectListDialogComponent = ({ isOpen, onClose, defaultFragranceList }) => {
//   const [selectedFragranceList, setSelectedFragranceList] = useState([]);

//   const handleCheckboxChange = (option) => {
//     setSelectedFragranceList((prevList) => (prevList.includes(option) ? prevList.filter((item) => item !== option) : [...prevList, option]));
//   };

//   const handleFilterClick = () => {
//     // Add your filter logic here
//     onClose();
//   };

//   return (
//     isOpen && (
//       <div className="fragranceFilterDialogContainer">
//         <div className="dialogHeaderContainer">
//           <span>{selectedFragranceList.length} נבחרו</span>
//           <button onClick={onClose}>איפוס</button>
//         </div>
//         <div className="fragranceOptionsContainer">
//           {defaultFragranceList.map((option) => (
//             <label key={option}>
//               <input type="checkbox" checked={selectedFragranceList.includes(option)} onChange={() => handleCheckboxChange(option)} />
//               {option}
//             </label>
//           ))}
//         </div>
//         <div className="actionContainer" onClick={handleFilterClick}>
//           <button>סנן</button>
//         </div>
//       </div>
//     )
//   );
// };

// export default FilterFromSelectListDialogComponent;
