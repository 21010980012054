import { statusCodesEnum } from "../../enums/general/statusCodesEnum";

export const getFailuerMessage = (statusCode, isUpdateAction) => {
  switch (statusCode) {
    case statusCodesEnum.CONFLICT:
      return { title: "פריט עם שם זהה קיים במערכת" };
    default:
      return {
        title: isUpdateAction ? "שגיאה בעדכון פריט" : "שגיאה בהוספת פריט",
      };
  }
};

export const validateProduct = (product) => {
  if (!product.productCode) return "יש להזין קוד מוצר";
  if (!product.productName) return "יש להזין שם מוצר";
  if (!product.description) return "יש להזין תיאור";
  if (product.price <= 0) return "יש להזין מחיר גדול מאפס";
  if (!product.categoryId) return "יש לבחור קטגוריה";
  return null;
};

export const buildProductFormData = (product, selectedProductImage) => {
  const formData = new FormData();
  formData.append("ProductCode", product.productCode);
  formData.append("ProductName", product.productName);
  formData.append("Description", product.description);
  formData.append("Price", +product.price);
  formData.append("StockQuantity", +product.stockQuantity);
  formData.append("CategoryId", product.categoryId);

  if (selectedProductImage) {
    formData.append("MainImage", selectedProductImage);
  }
  return formData;
};

export const getProductPrimaryImageUrl = (product) => {
  const primaryImage = product.images.find((image) => image.isPrimary);
  return primaryImage ? primaryImage.imageURL : null;
};
