import { useEffect, useState } from "react";
import shoppingCartService from "../services/local/shoppingCart.service";
import ShoppingCartQuantityInputComponent from "../components/shoppingCart/ShoppingCartQuantityInputComponent";
import AlertService from "../services/general/alert.service";
import TextAreaInputElementComponent from "../components/Generic/inputElements/TextAreaInputElementComponent";
import TextInputElementComponent from "../components/Generic/inputElements/TextInputElementComponent";
import { useNavigate } from "react-router-dom";

export const ShoppingCartPage = () => {
  const navigate = useNavigate();
  const [cartItemList, setCartItemList] = useState([]);

  useEffect(() => {
    const items = shoppingCartService.getCartItems();
    if (items) setCartItemList(items);
  }, []);

  const addItemCartQuantity = (cartItem) => {
    // Update quantity for items with matching productId and varientId (if varientId exists) or just productId
    const updatedCartItemList = cartItemList.map((item) => (item.productId === cartItem.productId && (!cartItem.varientId || item.varientId === cartItem.varientId) ? { ...item, quantity: item.quantity + 1 } : item));
    setCartItemList(updatedCartItemList);

    if (cartItem.varientId) shoppingCartService.updateItemQuantityByVarient(cartItem.productId, cartItem.varientId, cartItem.quantity + 1);
    else shoppingCartService.updateItemQuantity(cartItem.productId, cartItem.quantity + 1);
  };

  const reduceItemCartQuantity = async (cartItem) => {
    const newQuantity = cartItem.quantity - 1;

    if (newQuantity >= 1) {
      // Update quantity for items with matching productId and varientId (if varientId exists) or just productId
      const updatedCartItemList = cartItemList.map((item) => (item.productId === cartItem.productId && (!cartItem.varientId || item.varientId === cartItem.varientId) ? { ...item, quantity: newQuantity } : item));
      setCartItemList(updatedCartItemList);

      if (cartItem.varientId) shoppingCartService.updateItemQuantityByVarient(cartItem.productId, cartItem.varientId, newQuantity);
      else shoppingCartService.updateItemQuantity(cartItem.productId, newQuantity);
    } else {
      // Prompt to remove item from cart
      const userChoice = await AlertService.confirm("האם אתה בטוח שברצונך למחוק פריט זה מסל הפריטים שלך?", "שים לב! תוכל להוסיף את הפריט לסל שוב מעמוד הפריט", "כן", "לא");

      if (userChoice) {
        if (cartItem.varientId) shoppingCartService.removeItemByVarientId(cartItem.productId, cartItem.varientId);
        else shoppingCartService.removeItem(cartItem.productId);
        setCartItemList(shoppingCartService.getCartItems());
      }
    }
  };

  const handleCheckoutButtonClicked = () => {
    navigate("/checkout");
  };

  const handleChange = () => {};

  // Get Ellements Functions
  const getCartItemsListTitlesElement = () => {
    return (
      <div className="cartItemsListTitlesContainer">
        <div className="productTitle">מוצר</div>
        <div className="quantityTitle">כמות</div>
        <div className="totalAmountTitle">סה"כ</div>
      </div>
    );
  };

  const getCartItemsListElement = () => {
    return (
      <div className="cartItemsListContainer">
        {cartItemList.map((cartItem) => (
          <div key={cartItem.productId} className="cartItemCardContainer">
            {getCartItemContentElement(cartItem)}
            {getShoppingCartQuantityElement(cartItem)}
            {getPriceSummaryElement(cartItem)}
          </div>
        ))}
      </div>
    );
  };

  const getCartItemContentElement = (cartItem) => {
    return (
      <div className="itemContentContainer">
        <div className="itemImageContainer">
          <img src={cartItem.imageUrl} alt={cartItem.productName} />
        </div>
        <div className="itemDetailsContainer">
          <span>{cartItem.productName}</span>
          {cartItem.varientName && <span>{cartItem.varientName}</span>}
          <span>{cartItem.price} ₪</span>
        </div>
      </div>
    );
  };

  const getShoppingCartQuantityElement = (cartItem) => {
    const shoppingCartQuantityProps = {
      cartItem,
      handleAdd: addItemCartQuantity,
      handleReduce: reduceItemCartQuantity,
    };

    return (
      <div className="itemCartQuantityContainer">
        <ShoppingCartQuantityInputComponent props={shoppingCartQuantityProps} />
      </div>
    );
  };

  const getPriceSummaryElement = (cartItem) => {
    const totalPrice = cartItem.price * cartItem.quantity;

    return <span className="alignTextEnd cartItemCardPriceSummary">{totalPrice} ₪</span>;
  };

  const getOrderCommentsElement = () => {
    const props = {
      label: "הערות מיוחדות להזמנה",
      name: "comments",
      value: "",
      clearBaseDesign: true,
      customStyleClass: "orderCommentContainer",
      onChange: handleChange,
    };
    return <TextAreaInputElementComponent props={props} />;
  };

  // *******************************
  if (cartItemList.length === 0) return <div className="container">No Items To Show Yet</div>;

  return (
    <div className="shoppingCartPageContainer container">
      <div className="cartHeaderContainer">
        <h1>סל הקניות שלי</h1>
        <a>לכל המוצרים</a>
      </div>

      {getCartItemsListTitlesElement()}
      {getCartItemsListElement()}

      <div className="summaryContainer">
        {/* {getOrderCommentsElement()} */}
        <div className="summaryAndCheckOutContainer">
          <div className="summaryDetailsComtainer">
            <span>סיכום</span>
            <span>{shoppingCartService.calculateTotalPrice(cartItemList)} ₪</span>
          </div>

          <button className="buttonDesign" onClick={handleCheckoutButtonClicked}>
            תשלום
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShoppingCartPage;
