import { userActionTypes } from '../../actionTypes/user/userActionTypes'

const initialState = {
  stateInfo: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: null,
  },
  token: localStorage.getItem('jwtToken') || null,
  isAuthenticated: !!localStorage.getItem('jwtToken'),
  userInfo: null,
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userActionTypes.START_LOGIN:
    case userActionTypes.START_REGISTER:
    case userActionTypes.START_GET_USER_INFO:
      return setStateLoading(state, action)

    case userActionTypes.LOGIN_SUCCESS:
    case userActionTypes.REGISTER_SUCCESS:
      return setLoginSuccess(state, action)

    case userActionTypes.GET_USER_INFO_SUCCESS:
      return setUserInfoSuccess(state, action)

    case userActionTypes.LOGIN_FAILURE:
    case userActionTypes.REGISTER_FAILURE:
    case userActionTypes.GET_USER_INFO_FAILURE:
      return setStateFailure(state, action)

    case userActionTypes.LOGOUT:
      return setStateLogout(state, action)

    default:
      return state
  }
}

const setStateLoading = (state) => {
  return {
    ...state,
    stateInfo: {
      isLoading: true,
      isSuccess: false,
      isError: false,
      error: null,
    },
  }
}

const setStateFailure = (state, action) => {
  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: false,
      isError: true,
      error: action.data,
    },
  }
}

const setStateSuccess = (state, action) => {
  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: true,
      isError: false,
      error: null,
    },
  }
}

const setLoginSuccess = (state, action) => {
  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: true,
      isError: false,
      error: null,
    },
    token: action.data,
    isAuthenticated: true,
  }
}

const setStateLogout = (state, action) => {
  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: true,
      isError: false,
      error: null,
    },
    token: null,
    isAuthenticated: false,
  }
}

const setUserInfoSuccess = (state, action) => {
  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: true,
      isError: false,
      error: null,
    },
    userInfo: action.data,
  }
}

export default userReducer
