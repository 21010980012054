import React from "react";
import { useNavigate } from "react-router-dom";
import noImage from "../../assets/images/default/noimage1.png";
import { genericTableActionsEnum } from "../../enums/general/genericTableActionsEnum";

const GenericTableComponent = ({ data, actions }) => {
  const navigate = useNavigate();

  const getTableRecordImageElement = (item) => {
    const element = (
      <td>
        <img src={item.image ? item.image : noImage} alt={item.name} className="table-image" />
      </td>
    );
    return element;
  };

  const handleOnClick = (action, id) => {
    if (action.type === genericTableActionsEnum.function) {
      action.actionFunction(id);
    } else if (action.type === genericTableActionsEnum.navigate) {
      navigate(`${action.path}/${id}`);
    } else if (action.type === genericTableActionsEnum.customNavigate) {
      action.actionFunction(id);
    }
  };

  const getTableRecordActionElement = (item) => {
    const element = (
      <td>
        <div className="action-icons">
          {actions.map((action) => (
            <button key={action.name} className="action-button" onClick={() => handleOnClick(action, item.id)}>
              <i className={`icon-${action.icon}`}></i>
            </button>
          ))}
        </div>
      </td>
    );
    return element;
  };

  return (
    <div className="table-container">
      <table className="generic-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            {data.some((item) => item.image) && <th>Image</th>}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.name}</td>
              {getTableRecordImageElement(item)}
              {getTableRecordActionElement(item)}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GenericTableComponent;
