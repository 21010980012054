export const ShoppingCartQuantityInputComponent = ({ props }) => {
  const { cartItem, handleAdd, handleReduce } = props

  const handleAddClicked = () => {
    handleAdd(cartItem)
  }

  const handleReduceClicked = () => {
    handleReduce(cartItem)
  }

  return (
    <div className="shoppingCartQuantityInputContainer">
      <button className="quantityButton" onClick={handleAddClicked}>
        +
      </button>
      <input
        className="quantityInput"
        type="number"
        min={0}
        value={cartItem?.quantity}
      />
      <button className="quantityButton" onClick={handleReduceClicked}>
        -
      </button>
    </div>
  )
}

export default ShoppingCartQuantityInputComponent
